import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText, ValidationError } from '__components'
import { required, string } from '__util/forms/validators.js'
import { useRealOrMockContext } from '__util/test-helpers'

export default function RoleArn({ name, stepName }) {
  const {
    registerField,
    setField,
    state: { errors, fields },
  } = useRealOrMockContext(SiteWizardContext)

  React.useEffect(
    () =>
      registerField(stepName, name, [
        required((v, state) => state.fields.s3_bucket_access === 'role'),
        string({
          regex: /^arn:aws:iam::.+:role\/.+$/,
          regexExplanation:
            'Must use the format "arn:aws:iam::<account-id>:role/<role-name-with-path>"',
        }),
      ]),
    [registerField, name, stepName]
  )

  return (
    fields.s3_bucket_access === 'role' && (
      <Field>
        <Label label='RoleArn' required>
          <HelpText>
            Enter the specific role for MFT to connect with to authenticate to your bucket. This
            field is required because role bucket authentication is selected.
          </HelpText>
          <TextInput
            hasErrors={errors[name]}
            onChange={(e) => setField(name, e.target.value)}
            value={fields[name] || ''}
          />
          <ValidationError message={errors[name]} />
        </Label>
      </Field>
    )
  )
}
