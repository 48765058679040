import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { HelpText, ValidationError } from '__components'

export default function PushTargetDir({ error, formValues, onChange, value }) {
  const { initiated, push_target_site } = formValues
  if (initiated !== 'push') return null
  if (!push_target_site) return null
  if (!push_target_site.includes('S3')) return null

  return (
    <Label key='PushTargetDir' label='Target Directory' required>
      <HelpText>
        Examples:
        {/* eslint-disable-next-line */}
        <code>{"Inv/${date('yyyy')}/${date('MM')}/${date('dd')}/"}</code>
        {/* eslint-disable-next-line */}
        <code>{"customerserver/${date('yyyyMMdd'}/"}</code>
      </HelpText>
      <TextInput hasErrors={error} onChange={onChange} value={value} autoComplete='off' />
      <ValidationError message={error} />
    </Label>
  )
}
