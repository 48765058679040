import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { objToSelectOptions } from '__util/select.js'
import { SiteWizardContext } from '../SiteWizard.js'
import { useRealOrMockContext } from '__util/test-helpers'

const as2SignAlgOptions = {
  sha1: 'sha1',
  sha256: 'sha256',
  sha384: 'sha384 (rare)',
}

export default function AS2SignAlg({ stepName }) {
  const { registerField, setField, state } = useRealOrMockContext(SiteWizardContext)

  React.useEffect(
    () => registerField(stepName, 'sign_alg', [], 'sha256'),
    [registerField, stepName]
  )

  return (
    <Field>
      <Label label='Signing Algorithm'>
        <HelpText>Which signing algorithm do you need for this transfer?</HelpText>
        <RadioGroup
          list={objToSelectOptions(as2SignAlgOptions)}
          onChange={(value) => setField('sign_alg', value)}
          value={state.fields.sign_alg || ''}
        />
      </Label>
    </Field>
  )
}
