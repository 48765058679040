export const getMftGroups = (user) =>
  user.groups ? user.groups.filter((g) => g.startsWith('Application.MFT')) : []

export const isSupport = (user) =>
  getMftGroups(user).find(
    (g) => g.endsWith('Support') // also matches SplSupport
  )
export const isSupportOrHigher = (user) =>
  getMftGroups(user).find(
    (g) => g.endsWith('Support') || g.endsWith('Manager') || g.endsWith('Administrator')
  )
export const isDevOrHigher = (user) =>
  getMftGroups(user).find(
    (g) => g.endsWith('Developer') || g.endsWith('Manager') || g.endsWith('Administrator')
  )
export const isManagerOrHigher = (user) =>
  getMftGroups(user).find((g) => g.endsWith('Manager') || g.endsWith('Administrator'))
export const isLeadOrHigher = (user) => isManagerOrHigher(user)
export const isAdmin = (user) => getMftGroups(user).find((g) => g.endsWith('Administrator'))
export const isPlatformAdmin = (user) => isAdmin(user)
export const isGitHubUser = (user) => false
