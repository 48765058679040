import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { options } from '__util/forms/validators.js'
import { objToSelectOptions } from '__util/select.js'
import { roleOptions } from '../serialize.js'
import { useRealOrMockContext } from '__util/test-helpers'

export default function Role({ name, stepName }) {
  const { registerField, setField, state } = useRealOrMockContext(SiteWizardContext)
  const { fields } = state

  React.useEffect(
    () => registerField(stepName, name, [options(Object.keys(roleOptions))], 'source'),
    [registerField, name, stepName]
  )

  return (
    <Field>
      <Label label='Source or Target?'>
        <HelpText>A file transfer has two hops. Which does this site facilitate?</HelpText>
        <RadioGroup
          value={fields.role}
          onChange={(value) => setField(name, value)}
          list={objToSelectOptions(roleOptions)}
        />
      </Label>
    </Field>
  )
}
