import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText, ValidationError } from '__components'
import { required, string } from '__util/forms/validators.js'
import { useRealOrMockContext } from '__util/test-helpers'

export default function PartnerAS2Url({ name, stepName }) {
  const { registerField, setField, state } = useRealOrMockContext(SiteWizardContext)
  const { errors, fields } = state

  React.useEffect(
    () =>
      registerField(stepName, name, [
        required(),
        string({
          regex: /^(http:\/\/|https:\/\/).+$/,
          regexExplanation:
            'Must start with http:// or https:// and be a fully qualified domain name with optional path.',
        }),
      ]),
    [registerField, name, stepName]
  )

  return (
    <Field>
      <Label label='Partner AS2 URL' required>
        <HelpText>
          What AS2 URL should be used for this transfer? If you do not know, please contact the
          trading partner. Must be a fully qualified domain name with optional path.
        </HelpText>
        <TextInput
          hasErrors={errors[name]}
          onChange={(e) => setField(name, e.target.value)}
          value={fields[name] || ''}
        />
        <ValidationError message={errors[name]} />
      </Label>
    </Field>
  )
}
