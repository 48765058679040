import React from 'react'
import Wizard from '__components/wizard/Wizard.js'
import ItemDetails from '../common/ItemDetails.js'
import { generateName, parseSiteName } from './nameHelpers'
import { siteWizardStateToCertsJson, siteWizardStateToSiteJson } from './serialize'
import BasicsStep from './BasicsStep'
import NameStep from './NameStep'
import ProtocolStep, { getFieldNamesForProtocol } from './ProtocolStep'
import ConfirmStep from './ConfirmStep'
import SiteWizardHeader from './SiteWizardHeader.js'

const fieldsThatTriggerNameChange = ['jobName', 'role', 'protocol', 'systemName', 'otherSystemName']
const siteIsAlreadyDeployed =
  'There is already a site with this name deployed in MFT. To modify it, create a new version and return to this Sites tab.'

const SitePreview = (wizardState) => <ItemDetails fields={siteWizardStateToSiteJson(wizardState)} />

// Given a site, returns initial protocol-specific wizard state. If site
// is blank, sets reasonable default values.
// This is called by SiteWizard but defined here where it belongs.
const siteToWizardState = (site) => {
  const fields = {
    transfer_type: site.transfer_type || 'I',
    contact: site.contact || '',
    site_name: site.site_name,
    protocol: site.protocol || 'ssh',
    role: site.site_name?.startsWith('OUTBOUND_') ? 'target' : 'source',
  }

  const protocolFieldNames = getFieldNamesForProtocol(site.protocol)
  const siteProtocolFields = site[site.protocol || 'ssh'] || {}

  // Initialize wizard protocol fields where site being edited has values
  protocolFieldNames.forEach((name) => {
    if (name in siteProtocolFields) {
      fields[name] = siteProtocolFields[name]
    }
  })

  if (protocolFieldNames.includes('authType')) {
    if (fields.ssh_cert_name) {
      fields.authType = 'cert'
    } else if (fields.cerberus_sdb_path) {
      fields.authType = 'cerberus'
    } else {
      fields.authType = 'cyberark'
    }
  }

  if (protocolFieldNames.includes('s3_bucket_access')) {
    siteProtocolFields.role_arn
      ? (fields.s3_bucket_access = 'role')
      : (fields.s3_bucket_access = 'bucket')
  }

  return fields
}

export default function SiteWizard({
  commitChanges,
  debug = false,
  env,
  site,
  envConfig,
  getDeployedSites,
}) {
  const certs = React.useMemo(() => envConfig?.certs || [], [envConfig])
  const existingCertNames = React.useMemo(() => {
    return certs.filter((cert) => cert.cert_usage === 'private').map((cert) => cert.cert_name)
  }, [certs])
  const sites = envConfig?.sites || []
  const existingSiteNames = sites.map((s) => s.site_name)
  const isNew = React.useMemo(() => Object.keys(site).length === 0, [site])

  let initialFields = siteToWizardState(site)

  if (isNew) {
    initialFields.site_name = generateName(initialFields, existingSiteNames, isNew)
  } else if (site.site_name) {
    initialFields = Object.assign(initialFields, parseSiteName(site))
  }

  const onWizardValidate = React.useCallback(
    (errors, fields) => {
      if (
        isNew &&
        getDeployedSites()
          .map((s) => s.site_name)
          .includes(fields.site_name)
      ) {
        errors.site_name = siteIsAlreadyDeployed
      }
      return errors
    },
    [isNew, getDeployedSites]
  )

  const onWizardChange = React.useCallback(
    (fields, action) => {
      // Regenerate site name if needed, and save in wizard state
      if (action.type === 'setField') {
        if (fieldsThatTriggerNameChange.includes(action.name)) {
          fields.site_name = generateName(fields, existingSiteNames, isNew)
        }
      }
      return fields
    },
    [existingSiteNames, isNew]
  )

  const onWizardSave = React.useCallback(
    async (state, message = '') => {
      const { fields } = state
      const siteJson = siteWizardStateToSiteJson(state)
      const certsJson = siteWizardStateToCertsJson(fields, certs)

      if (certsJson) {
        await commitChanges('certs.json', certsJson, { message, refetchAccount: false })
      }
      await commitChanges(`sites/${fields.site_name}.json`, siteJson, { message })
    },
    [commitChanges, certs]
  )

  return (
    <>
      <Wizard
        debug={debug}
        initialState={{
          fields: initialFields,
          env,
          envConfig, // Some fields require access to account info like email_contact
          existingCertNames,
          isNew,
          serializer: SitePreview,
          getDeployedSites,
        }}
        onChange={onWizardChange}
        onSave={onWizardSave}
        onValidate={onWizardValidate}
        Context={SiteWizardContext}
        Header={SiteWizardHeader}
      >
        <BasicsStep name='Basics' />
        <NameStep name='Site Name' />
        <ProtocolStep name='Protocol Settings' />
        <ConfirmStep name='Confirm' />
      </Wizard>
    </>
  )
}

export const SiteWizardContext = React.createContext({})
