import React from 'react'
import { Button, Label, RadioGroup, Spinner, TextInput } from '@nike/epic-react-ui'
import { ButtonBar, Field, HelpText, ValidationError } from '__components'
import { useForm, validators } from '__util/forms/index.js'

const { email, required } = validators

const endpointTypeOptions = [
  {
    label:
      'Single (when endpoint has only one environment which is used as their non-production and production instance)',
    value: 'single',
  },
  {
    label: 'Multi (when endpoint has their dedicated non-production and production environments)',
    value: 'multi',
  },
]

export default function SSHCertNameCreateForm({
  isVisible,
  onClose,
  accountName,
  onSubmit,
  createCertLoading,
  newCertName,
  errorCreateCert,
}) {
  const { state, setField, resetForm } = useForm({
    fields: {
      accountName,
      partnerName: '',
      partnerEmail: '',
      endpointType: '',
      executorEmail: '',
    },
    validators: {
      partnerName: [required()],
      partnerEmail: [email(), required()],
      endpointType: [required()],
      executorEmail: [email(), required()],
    },
  })

  const { fields, errors } = state

  React.useEffect(() => {
    if (!isVisible) {
      resetForm()
    }
  }, [isVisible, resetForm])

  const isDisabled = !Object.values(fields).every((x) => x?.length) || Object.keys(errors).length

  const CloseButton = () => (
    <ButtonBar centered>
      <Button onClick={onClose} small>
        Close
      </Button>
    </ButtonBar>
  )

  return (
    <div className='SSHCertNameModal'>
      {errorCreateCert ? (
        <>
          <p className='text-error'>
            Something went wrong. Please try again or contact an MFT admin.
          </p>
          <CloseButton />
        </>
      ) : (
        <>
          {newCertName ? (
            <>
              <p>New SSH cert created:</p>
              <p>
                <b>{newCertName}</b>
              </p>
              <CloseButton />
            </>
          ) : (
            <>
              <h2>Add New SSH Key</h2>
              <Field>
                <Label label='Account Name'>
                  <TextInput disabled value={fields.accountName} />
                  <ValidationError message={errors.accountName} />
                </Label>
              </Field>
              <Field>
                <Label label='Partner Name' required>
                  <HelpText>
                    Human readable endpoint name for easy reading. Example:{' '}
                    <code>WMS, CITIBANK</code>
                  </HelpText>
                  <TextInput
                    hasErrors={errors.partnerName}
                    onChange={(e) => setField('partnerName', e.target.value)}
                    value={fields.partnerName || ''}
                  />
                  <ValidationError message={errors.partnerName} />
                </Label>
              </Field>
              <Field>
                <Label label='Partner email' required>
                  <HelpText>Single email address of technical endpoint owner</HelpText>
                  <TextInput
                    hasErrors={errors.executorEmail}
                    onChange={(e) => setField('executorEmail', e.target.value)}
                    value={fields.executorEmail || ''}
                  />
                  <ValidationError message={errors.executorEmail} />
                </Label>
              </Field>
              <Field>
                <RadioGroup
                  label='Endpoint Type'
                  list={endpointTypeOptions}
                  onChange={(value) => setField('endpointType', value)}
                  value={fields.endpointType}
                  required
                />
              </Field>
              <Field>
                <Label label='Contact email' required>
                  <HelpText>Your email address</HelpText>
                  <TextInput
                    hasErrors={errors.partnerEmail}
                    onChange={(e) => setField('partnerEmail', e.target.value)}
                    value={fields.partnerEmail || ''}
                  />
                  <ValidationError message={errors.partnerEmail} />
                </Label>
              </Field>
              {createCertLoading && (
                <HelpText className='text-center'>
                  Creating new cert. This may take a few moments.
                </HelpText>
              )}
              <ButtonBar centered>
                <Button onClick={onClose} inverse small>
                  Cancel
                </Button>
                <Button
                  disabled={isDisabled}
                  disabledTip={{ message: 'Please fill out all of the fields' }}
                  onClick={() => onSubmit(fields)}
                  small
                >
                  {createCertLoading ? <Spinner color='#fff' /> : 'Create Key'}
                </Button>
              </ButtonBar>
            </>
          )}
        </>
      )}
    </div>
  )
}
