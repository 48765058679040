import React from 'react'
import PropTypes from 'prop-types'
import Collapsible from 'react-collapsible'

export default function Accordion({
  children,
  enabled = true,
  initialOpenIndexes,
  onlyOpenSelected = true,
  ...props
}) {
  const initialState = {
    openIndexes: initialOpenIndexes || [],
  }
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const onTrigger = React.useCallback(
    (index) => {
      if (onlyOpenSelected) {
        dispatch({ type: 'select', index })
      } else {
        dispatch({ type: 'toggle', index })
      }
    },
    [onlyOpenSelected]
  )

  return children.map((child, index) => {
    return enabled ? (
      <Collapsible
        accordionPosition={index}
        handleTriggerClick={onTrigger}
        open={state.openIndexes.includes(index)}
        overflowWhenOpen='visible' // e.g. render tooltips without having to scroll
        trigger={child.props.trigger}
        {...props}
      >
        {child}
      </Collapsible>
    ) : (
      child
    )
  })
}

const reducer = (state, action) => {
  const { openIndexes } = state
  let exists,
    before,
    after,
    newIndexes = []

  switch (action.type) {
    case 'toggle':
      exists = openIndexes.indexOf(action.index)
      if (exists === -1) {
        newIndexes = [...openIndexes, action.index]
      } else {
        before = [...openIndexes].splice(0, exists)
        after = [...openIndexes].splice(exists + 1)
        newIndexes = before.concat(after)
      }

      return { ...state, openIndexes: newIndexes }

    case 'select': // Open just this section
      return { ...state, openIndexes: [action.index] }

    default:
      return state
  }
}

Accordion.propTypes = {
  children: PropTypes.array.isRequired,
  // Determines whether children are part of the accordion (true) or
  // simply rendered(false). Default true.
  enabled: PropTypes.bool,
  // Array of child indices to initially open
  initialOpenIndexes: PropTypes.array,
  // Only open the selected child and close all others (true), or
  // allow any child to be opened independently(false).Default true.
  onlyOpenSelected: PropTypes.bool,
}
