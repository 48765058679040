import React from 'react'
import { Button } from '@nike/epic-react-ui'
import { ButtonBar, ValidationError } from '__components'

export default function CommonConfirmStep({ isActive, previousStep, Context }) {
  const { validateWizard, state, onSave } = React.useContext(Context)
  const { errors, serializer } = state || { errors: {}, fields: {} }

  React.useEffect(() => {
    // Trigger a full validation that shows errors on all fields/steps
    if (isActive) {
      validateWizard()
    }
  }, [isActive, validateWizard])

  const errorFields = Object.keys(errors)
  const allStepsAreComplete = errorFields.length === 0
  const submitBtn = allStepsAreComplete ? 'Save Site' : 'Save Draft'
  const commitMessage = allStepsAreComplete
    ? '' // Use default commit message specified in AccountPage.js
    : 'Saving draft site (not completely valid)'
  const handleSave = () => onSave(state, commitMessage)

  return (
    <>
      <p>Let&apos;s make sure everything looks correct.</p>
      <p>
        Note: Default values may not be displayed in the JSON output below. For example, the default
        for <code style={{ margin: 0 }}>Max Concurrent File Transfers</code> is 0 (unlimited), so
        this setting would not appear below.
      </p>
      {serializer(state)}

      {!allStepsAreComplete && (
        <ValidationError
          message={`There are some problems on the form. Please go back and fix them.`}
        />
      )}
      <ButtonBar>
        <Button onClick={() => previousStep()} inverse small>
          Back
        </Button>
        <Button onClick={handleSave} small>
          {submitBtn}
        </Button>
      </ButtonBar>
    </>
  )
}
