import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText, ValidationError } from '__components'
import { required, folderPath } from '__util/forms/validators.js'
import { SiteWizardContext } from '../SiteWizard.js'
import { useRealOrMockContext } from '__util/test-helpers'

export default function DownloadFolder({ name, stepName }) {
  const { isFieldRequired, registerField, setField, state } =
    useRealOrMockContext(SiteWizardContext)

  const { errors, fields } = state

  React.useEffect(() => {
    registerField(stepName, name, [
      required((v, state) => state.fields.role === 'source'), // required for "inbound" sites
      folderPath(),
    ])
  }, [name, registerField, stepName])

  return (
    <Field>
      <Label label='Download Folder' required={isFieldRequired(name)}>
        <HelpText>Folder from which files will be downloaded.</HelpText>
        <TextInput
          hasErrors={errors[name]}
          onChange={(e) => setField(name, e.target.value)}
          value={fields[name] || ''}
        />
        <ValidationError message={errors[name]} />
      </Label>
    </Field>
  )
}
