import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { HelpText } from '__components'
import { useRealOrMockContext } from '__util/test-helpers'

export default function AS2Intro({ name, stepName }) {
  const { registerField } = useRealOrMockContext(SiteWizardContext)

  React.useEffect(() => registerField(stepName, name, []), [registerField, name, stepName])

  return (
    <>
      <HelpText>
        AS2 (Applicability Statement 2) is a specification about how to transport structured
        business-to-business (EDI B2B) data securely and reliably over the Internet. Security is
        achieved by using digital certificates and encryption.
      </HelpText>
      <hr />
    </>
  )
}
