import React from 'react'
import { Link } from 'raviger'
import { Container, Section, Spinner } from '@nike/epic-react-ui'
import { createCommit, createVersion, getAccount } from './api'
import { AlertsContext, PageTitle } from '__components'
import { GitHubContext } from '__src/github/GitHubAuth'
import AccountDetails from './details/AccountDetails'
import EnvSwitcher from './EnvSwitcher'
import VersionSwitcher from './VersionSwitcher'
import { useNavigate } from 'raviger'

const accountPageReducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.state }
    case 'waiting':
      return { ...state, waiting: action.why }
    default:
      return state
  }
}

export default function AccountPage({ name, version = 'main', env = 'dev' }) {
  const initialState = {
    configs: {},
    members: [],
    errorMessage: '',
    versions: [],
    // This is set once the GET call succeeds
    repoName: '',
    waiting: '',
  }

  const navigate = useNavigate()
  const alerts = React.useContext(AlertsContext)
  const github = React.useContext(GitHubContext)
  const token = github.getToken()
  const renewTokenIfExpired = github.renewTokenIfExpired
  const [page, dispatch] = React.useReducer(accountPageReducer, initialState)
  const [canSwitchEnvs, setCanSwitchEnvs] = React.useState(true)

  const fetchAccount = React.useCallback(() => {
    dispatch({ type: 'waiting', why: 'loading' })
    getAccount(token, name, version)
      .then((response) => {
        dispatch({
          type: 'set',
          state: {
            configs: response.configs,
            members: response.members,
            repoName: response.name,
            version: response.branch,
            versions: response.branches,
            waiting: '',
          },
        })
      })
      .catch((error) => {
        renewTokenIfExpired(error)

        const ver = version === 'main' ? 'DEPLOYED' : version
        dispatch({
          type: 'set',
          state: {
            errorMessage: `Failed to get details for account ${name} and version ${ver}`,
            waiting: '',
          },
        })
      })
  }, [renewTokenIfExpired, token, name, version])

  React.useEffect(() => {
    fetchAccount()
  }, [name, version, fetchAccount])

  React.useEffect(() => {
    if (page.waiting === 'fetchingLatest') {
      fetchAccount()
    }
  }, [page.waiting, fetchAccount])

  const setEnv = (env) => {
    navigate(`/accounts/${name}/${version}/${env}`, true)
    dispatch({ type: 'set', state: { env } })
  }

  const setVersion = (version) => {
    navigate(`/accounts/${name}/${version}/${env}`, true)
    // This state change will trigger a `fetchAccount` via a useEffect above.
    dispatch({ type: 'set', state: { version } })
  }

  const onCreateVersion = (newVersionName) => {
    createVersion(token, page.repoName, newVersionName)
    setTimeout(() => {
      setVersion(newVersionName) // Triggers a new fetch for the account configs on that new branch
    }, 2000) // give GitHub API enough time to reflect the new branch before fetching it
  }

  const commitChanges = React.useCallback(
    /**
     * options to commitChanges:
     *    message: string commit message
     *    env: optional env folder to place this file (default is selected env)
     */
    (filePath, fileContent, options = {}) => {
      const successDismissSeconds = options.successDismissSeconds || 5
      const refetchSeconds = options.refetchSeconds || 3
      const refetchAccount = options.refetchAccount !== false
      const successAlert = options.successAlert || 'Changes were saved'

      dispatch({ type: 'waiting', why: 'loading' })
      const message = options.message || `UI updated ${filePath}`
      return createCommit(token, name, version, options.env || env, filePath, fileContent, message)
        .then((response) => {
          if (refetchAccount) {
            setTimeout(() => {
              // Trigger the useEffect above to fetch latest repo contents for this branch
              dispatch({ type: 'waiting', why: 'fetchingLatest' })
              alerts.add({
                message: successAlert,
                level: 'success',
                dismiss: successDismissSeconds - refetchSeconds,
              })
            }, refetchSeconds * 1000) // Wait a few secs to ensure latest things propagate in GitHub API
          } else {
            alerts.add({ message: successAlert, level: 'success', dismiss: successDismissSeconds })
          }

          return response
        })
        .catch((error) => {
          console.error('API call failed:', error)
          const apiMessage = error.data?.message || error
          alerts.add({ message: `API call failed: ${apiMessage}`, level: 'error' })
        })
    },
    [alerts, dispatch, env, name, token, version]
  )

  return (
    <Container>
      <PageTitle title={name} />
      <Section title={name} style={{ marginTop: '1em' }}>
        {/* If repoName is defined, account is loaded and can show the switcher controls */}
        {page.waiting ? (
          <Spinner large className='centered' />
        ) : page.errorMessage ? (
          <p>
            Something went wrong. <Link href='/accounts'>Go back to Accounts</Link> and try again.
          </p>
        ) : (
          <>
            <div className='flex-container flex-space-between' style={{ marginBottom: '6em' }}>
              <VersionSwitcher
                version={version}
                setVersion={setVersion}
                versions={page.versions}
                onCreate={onCreateVersion}
              />
              <EnvSwitcher env={env} setEnv={setEnv} enabled={canSwitchEnvs} />
            </div>
            <div style={{ minHeight: '400px' }}>
              {page.waiting ? (
                <Spinner large className='centered' />
              ) : (
                <AccountDetails
                  name={name}
                  canEdit={version !== 'main'}
                  configs={page.configs}
                  members={page.members}
                  env={env}
                  setEnv={setEnv}
                  commitChanges={commitChanges}
                  onEditChange={(isEditing) => setCanSwitchEnvs(!isEditing)}
                />
              )}
            </div>
          </>
        )}
      </Section>
    </Container>
  )
}
