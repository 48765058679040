import React, { useState } from 'react'
import { Button } from '@nike/epic-react-ui'
import { ButtonBar, Tooltip } from '__components'

/**
 * Wraps a clickable component with a tooltip that warns the user they're leaving
 * unsaved changes. The tooltip shows 2 buttons: Cancel closes the tooltip, Continue
 * follows the link (i.e. calls onContinue prop).
 *
 * @param {bool} isUnsaved: true if form has unsaved changes
 * @param {function} onContinue: function that takes user to the original destination
 * @param {function} clickable: "render prop" function which supplies an onClick handler
 * for the content to be wrapped by this warning.
 * @param {string} message: optional message to show in the warning tooltip (overrides default).
 */
export const WarnIfUnsaved = (props) => {
  const [visible, setVisible] = useState(false)
  const msg =
    props.message || 'You are leaving this form but there are unsaved changes that will be lost.'
  const onClick = (e) => {
    if (props.isUnsaved) {
      setVisible(true)
    } else {
      props.onContinue()
    }
  }
  return (
    <Tooltip
      show={visible}
      content={
        <div style={{ textAlign: 'center' }}>
          <p>{msg}</p>
          <ButtonBar
            style={{ display: 'inline-flex' }} // horizontally center the buttons
            buttons={[
              <Button
                key={`btn-0`}
                onClick={() => setVisible(false)}
                id='cancelBtn'
                size='small'
                style={{ minWidth: 100 }}
                buttonType='secondary'
              >
                Cancel
              </Button>,
              <Button
                key={`btn-1`}
                onClick={props.onContinue}
                id='continueBtn'
                size='small'
                style={{ minWidth: 100 }}
              >
                Discard changes and continue
              </Button>,
            ]}
          />
        </div>
      }
      trigger='manual'
      placement='top'
    >
      {props.clickable(onClick)}
    </Tooltip>
  )
}
