import { emailCSVToArray } from '__util/forms/validators.js'

export function subWizardStateToJson(state) {
  const { fields } = state
  // Add common properties
  const sub = {
    subscription_folder: fields.subscription_folder,
  }

  if (fields.nikeInitiated) {
    sub.source_site_name = fields.source_site_name
    sub.route_name = fields.route_name
    if (fields.condition_type) {
      sub.condition_type = fields.condition_type
    }
    sub.custom_route = {
      simple_routes: getRoutes(fields, state.env),
    }
  } else {
    if (fields.post_client_download_action === 'DELETE') {
      // only add this prop if it's set to DELETE
      sub.post_client_download_action = fields.post_client_download_action
    }
  }

  // if prod, add to failure_email

  // action_on_step_failure - only add if true, value = 'FAIL'

  return sub
}

function getRoutes(fields, env) {
  if (!fields.simple_routes.length) {
    return []
  }

  const routes = fields.simple_routes.map((route, i) => getRouteJson(route, env))
  return routes
}

function getRouteJson(route, env) {
  const { simple_route_name, business_unit, filter_rule, failure_email } = route
  const step_sequence = getStepSequence(route)
  const routeJson = {
    // Type: route.initiated === 'push' ? 'Send to Partner' : 'Send to Account',
    simple_route_name,
    business_unit,
    filter_rule,
    failure_email: getFailureEmail(env === 'prod', failure_email),
    step_sequence,
  }

  if (step_sequence.includes('rename')) {
    routeJson['rename'] = getRenameStep(route)
  }

  if (step_sequence.includes('send_to_partner')) {
    routeJson['send_to_partner'] = getSendToPartnerStep(route)
  }

  if (step_sequence.includes('send_to_account')) {
    routeJson['send_to_account'] = getSendToAccountStep(route)
  }

  return routeJson
}

function getRenameStep(route) {
  // Props required in the JSON
  const step = {
    target_filename: route.target_filename,
    filter_filename: route.filter_filename || '*',
  }
  if (route.rename_action_on_step_failure) {
    step.action_on_step_failure = route.rename_action_on_step_failure
  }
  return step
}

function getSendToPartnerStep(route) {
  // Props required in the JSON or always present in route
  const step = {
    target_account: route.push_target_account,
    target_site: route.push_target_site,
    max_parallel_connections: route.max_parallel_connections,
  }

  // Optional props
  if (route.push_target_dir) {
    step.target_dir = route.push_target_dir
  }
  if (route.push_failure_retry_count) {
    step.failure_retry_count = route.push_failure_retry_count
  }
  if (route.push_failure_retry_interval) {
    step.failure_retry_interval = route.push_failure_retry_interval
  }
  if (route.action_on_step_failure) {
    step.action_on_step_failure = route.action_on_step_failure
  }

  return step
}

function getSendToAccountStep(route) {
  // Props required in the JSON or always present in route
  const step = {
    target_account: route.pull_target_account,
    target_dir: route.pull_target_dir,
    // not part of MVP
    // target_filename
  }

  // Optional props
  if (route.pull_target_file_collision) {
    step.target_file_collision = route.pull_target_file_collision
  }
  if (route.action_on_step_failure) {
    step.action_on_step_failure = route.action_on_step_failure
  }
  return step
}

// De-dupe email addresses and add required ones for PRD environment
function getFailureEmail(includeFrontlineSupport, emailString) {
  if (!emailString) return
  let emails = emailCSVToArray(emailString)

  const frontline = 'Lst-Frontline.MFT@nike.com'
  if (includeFrontlineSupport) {
    emails.push(frontline)
  } else {
    emails = emails.filter((email) => email.toLowerCase() !== frontline.toLowerCase())
  }

  emails = emails.filter((e) => e !== '') // Remove empty strings
  emails = [...new Set(emails)] // De-dupe
  return emails.join(', ')
}

function getStepSequence(route) {
  const seq = []
  // First rename files
  if (route.rename === true) {
    seq.push('rename')
  }
  if (route.initiated === 'push') {
    seq.push('send_to_partner')
  } else {
    seq.push('send_to_account')
  }
  return seq
}

/**
 * Given a subscription JSON file structure, return an object of the human-readable
 * equivalent.
 *
 * @param {object} sub
 * @returns object
 */
export function subJsonToDetails(sub) {
  const {
    subscription_folder,
    source_site_name,
    route_name,
    condition_type,
    post_client_download_action,
    ...rest
  } = sub

  const fields = {
    'Subscription Folder': subscription_folder,
    'Source Site': source_site_name,
    'Route Name': route_name,
    'Condition Type': condition_type,
    'Post Action': post_client_download_action,
    ...rest,
  }

  return fields
}
