import React from 'react'
import { Button, Spinner, Table, Tooltip } from '@nike/epic-react-ui'
import { Collapsible, ExtraTableControls } from '__components'
import { cannotEditDeployed } from '__accounts/VersionSwitcher.js'
import DetailsLink from './common/DetailsLink.js'
import EditLink from './common/EditLink.js'
import SubWizard from './subs/SubWizard.js'
import { subJsonToDetails } from './subs/serialize.js'
import DetailsContext from './DetailsContext.js'

const triggerStyle = { margin: '1em auto', width: 'fit-content' }

const AddButton = ({ disabled, loading, onClick }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      disabledTip={{ message: cannotEditDeployed }}
      small
    >
      {loading ? <Spinner color='#fff' /> : 'Add a subscription'}
    </Button>
  )
}

export default function Subs({ isEditing, setEditing, cancelEditing }) {
  const details = React.useContext(DetailsContext)
  const { canEdit, commitChanges, env, envConfig } = details
  const subs = React.useMemo(() => envConfig?.subscriptions || [], [envConfig])
  // Store the subscription being edited in state to pass it to the wizard below
  const [editSub, setEditSub] = React.useState({})

  const openWizard = React.useCallback(
    (sub) => {
      setEditSub(sub)
      setEditing()
    },
    [setEditing]
  )

  const resetWizard = React.useCallback(() => {
    setEditSub({})
    cancelEditing()
  }, [setEditSub, cancelEditing])

  const columns = React.useMemo(() => {
    return [
      {
        accessor: 'subscription_folder',
        Header: 'Folder',
        Cell: ({ value }) => {
          const sub = subs.find((sub) => sub.subscription_folder === value) || {}
          return (
            <DetailsLink
              title={sub && sub.subscription_folder}
              getFields={() => subJsonToDetails(sub)}
            />
          )
        },
      },
      {
        accessor: 'custom_route.simple_routes',
        Header: 'Routes',
        Cell: ({ value }) => {
          if (value === undefined) {
            return ''
          }

          // value is an array of route objects
          return (
            <ol style={{ marginLeft: '2em' }}>
              {value.map((route, index) => (
                <li key={`r-${index}`}>{route.simple_route_name}</li>
              ))}
            </ol>
          )
        },
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row }) => {
          const sub = subs.find((sub) => sub.subscription_folder === row?.cells?.[0].value) || {}
          return <EditLink item={sub} canEdit={canEdit} openWizard={openWizard} show={!isEditing} />
        },
      },
    ]
  }, [canEdit, isEditing, openWizard, subs])

  return (
    <>
      {!isEditing && (
        <ExtraTableControls>
          <AddButton disabled={!canEdit} onClick={() => openWizard({})} />
        </ExtraTableControls>
      )}
      <Table
        columns={columns}
        data={subs}
        withSearchBar
        searchBarPlaceholder='Search subscriptions...'
      />
      {canEdit ? (
        <>
          <Collapsible
            open={isEditing}
            trigger={<AddButton disabled={isEditing} onClick={() => openWizard({})} />}
            triggerWhenOpen={
              <Button onClick={() => resetWizard()} inverse>
                Cancel
              </Button>
            }
            triggerStyle={triggerStyle}
            triggerTagName='div'
          >
            {isEditing && (
              <SubWizard
                commitChanges={commitChanges}
                env={env}
                envConfig={envConfig}
                sub={editSub}
              />
            )}
          </Collapsible>
        </>
      ) : (
        <div style={triggerStyle}>
          <Tooltip message={cannotEditDeployed}>
            <Button disabled>Add</Button>
          </Tooltip>
        </div>
      )}
    </>
  )
}
