import React from 'react'
import { RadioGroup, Label } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { SiteWizardContext } from '../SiteWizard.js'
import { downloadSubfoldersOptions } from '../serialize.js'
import { useRealOrMockContext } from '__util/test-helpers'

export default function DownloadSubfolders({ name, stepName }) {
  const { registerField, setField, state } = useRealOrMockContext(SiteWizardContext)
  const { fields } = state || { errors: {}, fields: {} }

  React.useEffect(() => registerField(stepName, name, [], 'false'), [registerField, name, stepName])

  return (
    fields.role === 'source' && (
      <Field>
        <Label label='Download Subfolders'>
          <HelpText>Would you like to include all files in sub folders?</HelpText>
          <RadioGroup
            list={downloadSubfoldersOptions}
            onChange={(value) => setField(name, value)}
            value={fields[name]}
          />
        </Label>
      </Field>
    )
  )
}
