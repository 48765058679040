import React from 'react'

/**
 * Floats children to the right and sets some styles to make Button and Tooltip
 * work in the presence of Epic's search bar (div[class^="searchFilterSection"]).
 *
 * This element must be rendered just before Table.
 */
export default function ExtraTableControls({ children }) {
  return (
    <div
      style={{
        float: 'right',
        position: 'relative',
        // Keep button below modals like SiteCopyModal with its z-index of 7 (why so low?)
        zIndex: 6,
      }}
    >
      {children}
    </div>
  )
}
