import React from 'react'
import { IconButton, NextGen } from '@nike/epic-react-ui'
import DetailsContext from '../DetailsContext.js'
import SiteCopyModal from './SiteCopyModal.js'

function SiteCopyLink({ site, show }) {
  const [showModal, setShowModal] = React.useState(false)
  const details = React.useContext(DetailsContext)
  const { canEdit, env } = details
  const canCopy = canEdit && ['dev', 'qa'].includes(env)

  return (
    show &&
    canCopy && (
      <>
        <NextGen.Tooltip message={`Copy to next environment`} position='left'>
          <IconButton onClick={() => setShowModal(true)} type='TrendingUp' bare iconSize='s' />
        </NextGen.Tooltip>
        {showModal && (
          <SiteCopyModal onClose={() => setShowModal(false)} show={showModal} site={site} />
        )}
      </>
    )
  )
}

export default SiteCopyLink
