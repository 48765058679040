import React from 'react'
import DetailsContext from './DetailsContext.js'
import AuthTypeField from './fileSystem/AuthTypeField'
import CerberusPathField from './fileSystem/CerberusPathField'
import CertField from './fileSystem/CertField'
import EndpointField from './fileSystem/EndpointField'
import RequiresAccessField from './fileSystem/RequiresAccessField'
import UserNameField from './fileSystem/UserNameField'
import WhitelistField from './fileSystem/WhitelistField'

export default function FileSystemDetails({ EditControls }) {
  const details = React.useContext(DetailsContext)
  const { env, envConfig } = details

  const user = envConfig.user || {}
  const requiresFileSystemAccess = Object.keys(user).length > 0
  const loginCert = envConfig?.certs?.find((c) => c.cert_usage === 'login')
  const authType =
    user?.use_password === 'true' ? (user.cerberus_sdb_path ? 'cerberus' : 'cyberark') : 'cert'

  return (
    <>
      <EditControls />

      <RequiresAccessField disabled value={requiresFileSystemAccess} />
      {requiresFileSystemAccess && (
        <>
          <UserNameField value={user?.user_name} />

          {envConfig?.account?.transfer_type === 'E' && (
            <WhitelistField disabled value={user?.endpoint && user?.endpoint?.includes('-wl')} />
          )}

          <EndpointField value={user.endpoint} />
          <AuthTypeField disabled env={env} value={authType} />

          {authType === 'cerberus' && (
            <CerberusPathField disabled value={user?.cerberus_sdb_path} />
          )}
          {authType === 'cert' && (
            <CertField
              accountName={envConfig?.account?.account_name}
              env={env}
              disabled
              value={loginCert?.cert_name || ''}
            />
          )}
        </>
      )}
    </>
  )
}
