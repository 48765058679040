import React from 'react'
import { Link } from 'raviger'
import config from '__config'
import IntegratedPlatform from './IntegratedWrapper'
import appLogo from './MFT.png'

function Navigation({ accessToken, children }) {
  const { appName, basePath, platformId } = config

  const navbarRoutes = []

  const navBarConfig = {
    name: appName,
    logo: appLogo,
    routes: navbarRoutes,
    rootPath: basePath || '/',
    RouterLink: ({ href, ...props }) => <Link href={href} {...props} />,
  }

  return (
    <IntegratedPlatform
      accessToken={accessToken}
      navBarConfig={navBarConfig}
      platformId={platformId}
    >
      {children}
    </IntegratedPlatform>
  )
}

export default Navigation
