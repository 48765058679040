import React from 'react'
import { Label, RadioGroup, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import CyberArkHelp from '../common/CyberArkHelp.js'

export default function AuthTypeField({ options, env, disabled = false, onChange, value = '' }) {
  return (
    <Field>
      <Label label='Authentication' />
      <HelpText>How will you authenticate this connection?</HelpText>
      {disabled ? (
        <TextInput disabled value={value} />
      ) : (
        <RadioGroup list={options} onChange={onChange} value={value} />
      )}
      {value === 'cyberark' && <CyberArkHelp env={env} />}
    </Field>
  )
}
