import { usePath, useQueryParams } from 'raviger'
import { setAuthCode } from './GitHubAuth.js'
import config from '__config'

// Intercept the `code` query param returned by GitHub to
// prevent Okta from interpreting it as a new login attempt.
export default function InterceptGitHubAuthCode({ fallback }) {
  const path = usePath(config.basePath)
  const [{ code }, setQueryParams] = useQueryParams()

  // path may be /github-authorized or /mft/github-authorized in Platform Console contexts
  if (path.includes('github-authorized') && code) {
    // Save in sessionStorage so it can be accessed later in GitHubAuth.js,
    // when user's Okta token is available to make authenticated API calls
    setAuthCode(code)

    // Remove `code` query param so okta-auth-js doesn't see it
    setQueryParams({}, { replace: true })
  }

  return fallback()
}
