import React from 'react'
import { Label, RadioGroup, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { findValue } from '__util/select'

const useWhitelistOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export default function AuthTypeField({ disabled = false, onChange, value = '' }) {
  const option = findValue(useWhitelistOptions, value)
  return (
    <Field>
      <Label label='Will the partner need to whitelist Nike?' />
      <HelpText>
        If Trading Partner is making a SFTP/ FTPs/ AS2 connection into Nike&apos;s server, does
        Trading Partner need to whitelist Nike IPs (add to Trading Partner&apos;s firewall rules) in
        order to make this connection into Nike?
      </HelpText>
      {disabled ? (
        <TextInput disabled value={option?.label || ''} />
      ) : (
        <RadioGroup list={useWhitelistOptions} onChange={onChange} value={value} />
      )}
    </Field>
  )
}
