import React, { createContext, useContext, useEffect } from 'react'
import config from '__config'
import { selectAccessToken, useAuthStore } from '@nike/aegis-auth-react'
import { NarkPlatform } from '@nike/nark-sdk'

export function getOktaToken() {
  try {
    const oktaToken = JSON.parse(window.sessionStorage.getItem('okta-token-storage'))
    const { accessToken } = oktaToken.accessToken
    return accessToken
  } catch (error) {
    return ''
  }
}

export const NarkContext = createContext(null)

export function NarkProvider({ accessToken, children }) {
  const token = useAuthStore(selectAccessToken) || accessToken || getOktaToken()
  const narkPlatform = token
    ? new NarkPlatform({
        token,
        ...config.nark,
      })
    : {}
  return <NarkContext.Provider value={narkPlatform}>{children}</NarkContext.Provider>
}

export function useNark() {
  const nark = useContext(NarkContext)
  return nark
}

export function useNarkOnPageLoad({ nark, pageName }) {
  useEffect(() => {
    if (nark && pageName) {
      nark.log({
        source: 'ui',
        feature: `${pageName}-page`,
        operation: 'page.load',
      })
    } else {
      console.log('[nark-error] - missing `pageName` or `nark`')
    }
  }, [pageName, nark])
}
