import React from 'react'
import { Button, Icon, NextGen } from '@nike/epic-react-ui'
import DetailsContext from '../DetailsContext.js'
import { AlertsContext } from '__components'
import ItemDetails from '../common/ItemDetails.js'

function getSiteCopyableProps(site) {
  const { contact, ...keepSiteFields } = site
  const protocolFields = site[site.protocol]
  const {
    host,
    username,
    delete_after_download,
    download_folder,
    download_pattern,
    cerberus_sdb_path,
    bucket,
    system_metadata,
    download_object_key,
    nike_as2_id,
    nike_as2_cert_name,
    partner_as2_id,
    partner_as2_url,
    partner_as2_cert_name,
    sign_alg,
    ...keepProtocolFields
  } = protocolFields
  const copiedSite = { ...keepSiteFields, [site.protocol]: { ...keepProtocolFields } }
  return copiedSite
}

function SiteCopyModal({ onClose, show, site }) {
  const alerts = React.useContext(AlertsContext)
  const details = React.useContext(DetailsContext)
  const { allConfigs, commitChanges, env, setEnv } = details

  const targetEnv = env === 'dev' ? 'qa' : 'qa2'
  const [siteExistsInTargetEnv, setExists] = React.useState(false)

  React.useEffect(() => {
    setExists(
      allConfigs[targetEnv]?.sites?.find((s) => s.site_name === site.site_name) !== undefined
    )
  }, [allConfigs, site, targetEnv])

  const onSave = React.useCallback(async () => {
    const message = `Copied site ${site.site_name} from ${env.toUpperCase()}.`
    commitChanges(`sites/${site.site_name}.json`, getSiteCopyableProps(site), {
      message,
      env: targetEnv,
      successAlert: message + ' It must be edited to complete all required fields.',
    })
      // Send the user to the target env, ensuring the new site is loaded. We first select the target
      // env * before * the new account details are fetched which is done in commitChanges after a 3s delay.
      .then((res) => setEnv(targetEnv))
      .catch((error) => alerts.add({ message: String(error), level: 'error' }))
  }, [alerts, commitChanges, env, setEnv, site, targetEnv])

  return (
    site && (
      <NextGen.Modal show={show} onClose={onClose} modalSize='lg' closeButton>
        <NextGen.Modal.Title>
          <h3>Copy site {site.site_name}</h3>
          <div className='CopyEnvDiagram'>
            {targetEnv === 'qa' && (
              <>
                <Button inverse disabled small>
                  DEV
                </Button>{' '}
                <Icon type='ArrowRight' />{' '}
                <Button disabled small>
                  QA
                </Button>
              </>
            )}
            {targetEnv === 'qa2' && (
              <>
                <Button inverse disabled small>
                  QA
                </Button>{' '}
                <Icon type='ArrowRight' />{' '}
                <Button disabled small>
                  QA2
                </Button>
              </>
            )}
          </div>
        </NextGen.Modal.Title>
        <NextGen.Modal.Content>
          {siteExistsInTargetEnv && (
            <div className='text-center warning epic-color-accent'>
              <Icon type='AlertCircle' />A site with this name already exists in{' '}
              {targetEnv.toUpperCase()}.
            </div>
          )}
          <p className='text-center'>
            A site with the following fields will be copied to the new environment. Some values are
            not copied; the new site will need to be edited to be completed.
          </p>
          <ItemDetails fields={getSiteCopyableProps(site)} />
        </NextGen.Modal.Content>
        <NextGen.Modal.Actions>
          <Button onClick={onClose} inverse>
            Cancel
          </Button>
          <Button onClick={onSave}>{siteExistsInTargetEnv ? 'Replace' : 'Copy'}</Button>
        </NextGen.Modal.Actions>
      </NextGen.Modal>
    )
  )
}

export default SiteCopyModal
