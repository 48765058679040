import React from 'react'
import { Label, Select, TextInput } from '@nike/epic-react-ui'
import { HelpText, ValidationError } from '__components'
import { findValue } from '__util/select.js'

const sampleRules = [
  {
    // eslint-disable-next-line
    value: "${transfer.target.matches('.*')}",
    label: 'All files to be routed',
  },
  // eslint-disable-next-line
  { value: '${transfer.transferredBytes eq 0}', label: 'Zero Byte Files' },
  // eslint-disable-next-line
  { value: "${transfer.target.matches('FILENAME_123.*[.]ext1[.]gpg')}", label: 'Single File' },
  { value: '', label: 'I will type my own rule' },
]

export default function FilterRule({ error, onChange, value }) {
  return (
    <div key='FR'>
      <Label label='Filter Rule' required>
        <HelpText>
          Define which files should be processed. To see examples of this special expression
          language, choose from the sample rules below.
        </HelpText>
        <TextInput hasErrors={error} onChange={onChange} value={value} />
        <ValidationError message={error} />
      </Label>

      <Label label='Sample Rules'>
        <Select
          options={sampleRules}
          onChange={onChange}
          value={findValue(sampleRules, value) || ''}
        />
      </Label>
    </div>
  )
}
