import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, FilePicker, HelpText } from '__components'

export default function CertField({ env, accountName, onChange, disabled, value }) {
  const getContentsOfFile = (file) => {
    if (file) {
      const reader = new FileReader()

      reader.readAsText(file)

      reader.onload = function () {
        onChange({ fileName: file.name, fileContents: reader.result })
      }

      reader.onerror = function () {
        console.error('File read error')
      }
    } else {
      onChange()
    }
  }
  return (
    <Field>
      <Label label='Public Certificate' />
      <HelpText>Please select your public SSH key file. Only RSA format is supported.</HelpText>
      {disabled ? (
        <>
          <TextInput disabled value={value} />
        </>
      ) : (
        <FilePicker value={value} onSelect={(file) => getContentsOfFile(file)} />
      )}
    </Field>
  )
}
