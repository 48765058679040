import React from 'react'
import { Button, Label } from '@nike/epic-react-ui'

export default function EnvSwitcher({ env, setEnv, enabled = true }) {
  return (
    <div style={{ height: '50px' }}>
      <Label label='Environment'>
        <div className='rows'>
          <div className='columns'>
            <Button onClick={() => setEnv('dev')} inverse={env !== 'dev'} disabled={!enabled} small>
              DEV
            </Button>{' '}
            <Button onClick={() => setEnv('qa')} inverse={env !== 'qa'} disabled={!enabled} small>
              QA
            </Button>{' '}
            <Button onClick={() => setEnv('prd')} inverse={env !== 'prd'} disabled={!enabled} small>
              PRD
            </Button>{' '}
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => setEnv('qa2')} inverse={env !== 'qa2'} disabled={!enabled} small>
              QA2
              <div style={{ lineHeight: 0, fontSize: '9px', color: '#999' }}>Optional</div>
            </Button>
          </div>
        </div>
      </Label>
    </div>
  )
}
