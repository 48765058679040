import React from 'react'
import { Label, Select } from '@nike/epic-react-ui'
import { HelpText, ValidationError } from '__components'
import { findValue } from '__util/select.js'

const renameOperations = [
  {
    label: 'Change extension',
    // eslint-disable-next-line
    value: '${basename(currentfulltarget)}.dat',
    example: 'Inbound.dat',
  },
  {
    label: 'Add timestamp',
    // eslint-disable-next-line
    value: '${basename(currentfulltarget)}_${timestamp}${extension(currentfulltarget)}',
    example: 'Inbound_1593656413.txt',
  },
  {
    label: 'New name with timestamp and extension',
    // eslint-disable-next-line
    value: 'ABC_$(timestamp)${extension(currentfulltarget)}',
    example: 'ABC_1593656413.txt',
  },
  {
    label: 'Add random to name',
    // eslint-disable-next-line
    value: '${basename(currentfulltarget)}${random())}.dat',
    example: 'InboundF14D5DD4606DF7AF066D3C2044A618A5.dat',
  },
  {
    label: 'Add date/time to name',
    value:
      // eslint-disable-next-line
      "${basename(currentfulltarget)}_${date('yyyyMMddhhmmssSSS')}${extension(currentfulltarget)}",
    example: 'Inbound_20200701235959122.txt',
  },
  {
    label: 'Remove extension',
    // eslint-disable-next-line
    value: '${basename(currentfulltarget)}',
    example: 'Inbound',
  },
]

export default function RenameTargetFilename({ error, formValues, onChange, value }) {
  const options = renameOperations.map((o) => ({ label: o.label, value: o.value }))

  return (
    formValues.rename === true && (
      <Label key='RTF' label='Rename Target Filename' required>
        <HelpText>
          Please select how you would like your file renamed before transferring. If your specific
          renaming ability to not present here please reach LST-MFT@nike.com.
        </HelpText>
        <Select
          hasErrors={error}
          onChange={onChange}
          options={options}
          value={findValue(options, value)}
        />
        <RenameFilenameExample value={value} />
        <ValidationError message={error} />
      </Label>
    )
  )
}

function RenameFilenameExample({ value }) {
  const exampleOperation = renameOperations.find((op) => op.value === value) || {}
  const example = exampleOperation.example
  return (
    <HelpText>
      <p>Action: {value && <code>{value}</code>}</p>
      {example && (
        <>
          <h4>Example:</h4>
          <p>
            <span style={{ display: 'block' }}>
              <code>Inbound.txt</code> &rarr;
            </span>
            <span style={{ display: 'block' }}>
              <code>{example}</code>
            </span>
          </p>
        </>
      )}
    </HelpText>
  )
}
