import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { listToSelectOptions } from '__util/select.js'
import { SiteWizardContext } from '../SiteWizard.js'
import { useRealOrMockContext } from '__util/test-helpers'

const as2ContentTypes = ['application/octet-stream']
const defaultValue = 'application/octet-stream'

export default function AS2ContentType({ stepName }) {
  const { registerField, setField, state } = useRealOrMockContext(SiteWizardContext)

  React.useEffect(
    () => registerField(stepName, 'content_type', [], defaultValue),
    [registerField, stepName]
  )

  return (
    <Field>
      <Label label='Content Type'>
        <HelpText>
          Currently only one content_type is supported. If an additional value is needed please
          reach <code>LST-MFT@nike.com</code> to add feature request.
        </HelpText>
        <RadioGroup
          list={listToSelectOptions(as2ContentTypes)}
          onChange={(value) => setField('content_type', value)}
          value={state.fields.content_type || ''}
        />
      </Label>
    </Field>
  )
}
