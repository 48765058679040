import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Icon, Label, RadioGroup } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { s3BucketAccessOptions } from '../serialize.js'
import { useRealOrMockContext } from '__util/test-helpers'

const documentationAlert = (
  <>
    <HelpText>
      Example of configuring trusted relationship between roles:
      <p>
        <a href='https://confluence.nike.com/display/ERPPMFT/AWS+S3+Transfers+in+Axway'>
          CrossAccountTrustedRelationship
        </a>
      </p>
    </HelpText>
  </>
)

const email = (env, envConfig, field, fields) => {
  const emailHelpText =
    'Automation for allow listing ARNs is not implemented. The MFT team will need to complete your request manually.'
  const emailHeader = `mailto:LST-MFT@nike.com?subject=MFT SSP - S3 site update help`
  const emailBody = `&body=Account%20name%3A%20${envConfig.account.account_name}%0AEnvironment%3A%20${env}%0ASite%20Name%3A%20${fields.site_name}%0A`
  const emailDetail =
    field === 'bucket'
      ? `Bucket%20Name%3A%20${fields.s3_bucket}`
      : `Version%20link%20where%20you%20provided%20role_arn%3A%20${window.location.href}`
  return (
    <>
      <HelpText className='notify'>
        <p>{emailHelpText}</p>
        <p className='helptext-has-icon'>
          <Icon type='AlertCircleFilled' size='s' />
          <span>
            Please be sure to{' '}
            <a href={`${emailHeader}${emailBody}${emailDetail}`}>Email the MFT Team</a>
          </span>
        </p>
      </HelpText>
    </>
  )
}

const addRole = (env, field) => {
  const helpText =
    field === 'bucket'
      ? 'Please add the following MFT roles to your provider to allow for MFT to connect to the provided bucket'
      : 'Please add the following MFT role so that MFT may assume the role provided'
  const addRoleRole =
    env === 'prd'
      ? 'arn:aws:iam::284722348816:role/mft_ec2provisioner'
      : 'arn:aws:iam::289036058743:role/mftaxway_ec2provisioner'
  return (
    <>
      <HelpText className='notify'>
        <p className='helptext-has-icon'>
          <Icon type='AlertCircleFilled' size='s' />
          <span>{helpText}</span>
        </p>
        <pre>
          <code>{addRoleRole}</code>
        </pre>
      </HelpText>
    </>
  )
}
export default function S3BucketAccess({ name, stepName }) {
  const {
    registerField,
    setField,
    state: { env, envConfig, fields, isNew, changed },
  } = useRealOrMockContext(SiteWizardContext)
  React.useEffect(
    () => registerField(stepName, name, [], 'bucket'),
    [name, registerField, stepName]
  )

  return (
    <Field>
      <Label label='S3 authentication type' required>
        <RadioGroup
          list={s3BucketAccessOptions}
          onChange={(value) => setField(name, value)}
          value={fields[name]}
        />
      </Label>
      <HelpText>What level of access should MFT have to your S3 bucket?</HelpText>
      {(isNew || changed[name]) &&
        (fields[name] === 'bucket' ? (
          <>
            {email(env, envConfig, fields[name], fields)}
            {addRole(env, fields[name])}
          </>
        ) : (
          <>
            {addRole(env, fields[name])}
            {documentationAlert}
            {email(env, envConfig, fields[name], fields)}
          </>
        ))}
    </Field>
  )
}
