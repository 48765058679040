import React, { useRef } from 'react'
import { Button, IconButton, Spinner } from '@nike/epic-react-ui'

const FilePicker = ({ name, value, onSelect, onClear, loading, error }) => {
  const fileInput = useRef()

  const openPicker = (e) => {
    fileInput.current.click()
  }
  const clear = (e) => {
    fileInput.current.value = ''
    onClear && onClear()
  }

  return (
    <div className='FilePicker' key={`file-${name}`}>
      {loading ? (
        <Button>
          <Spinner color='#fff' />
        </Button>
      ) : (
        <IconButton onClick={openPicker} type='share' className='FilePicker-pick' />
      )}
      <span className={`FilePicker-file ${error && 'text-error'}`}>{value}</span>
      {value && <IconButton onClick={clear} type='delete' className='FilePicker-clear' inverse />}

      <input
        type='file'
        ref={fileInput}
        onChange={(e) => onSelect(fileInput.current.files[0])}
        name={name}
      />
    </div>
  )
}

export default FilePicker
