import http from '__util/http'
import config from '__config'

export const listAccounts = async (gitHubToken) => {
  const url = `${config.apiUrl}/accounts`
  const accounts = await http.get(url, undefined, { 'x-github-token': gitHubToken })
  // API should filter out repositories that are not actual accounts (e.g. TEMPLATE_REPOSITORY).
  // End users will only see accounts they have permission for.
  return accounts
}

export const getAccount = async (gitHubToken, name, branch = 'main') => {
  const account = await http.get(
    `${config.apiUrl}/accounts/${name}`,
    { branch },
    { 'x-github-token': gitHubToken }
  )
  return account
}

export const getAccountLoginCerts = async (name, env = '') => {
  const certs = await http.get(`${config.apiUrl}/certs/${name}/${env}`)
  return certs
}
export const uploadLoginCert = async (fileName, content, account, env) => {
  const response = await http.post(`${config.apiUrl}/certs/${account}/${env}`, {
    fileName,
    content,
  })
  return response
}

export const createAccount = async (gitHubToken, name) => {
  const results = await http.post(
    `${config.apiUrl}/accounts`,
    { name: name },
    { 'x-github-token': gitHubToken }
  )
  return results
}

export const createVersion = async (gitHubToken, accountName, versionName) => {
  const response = await http.post(
    `${config.apiUrl}/accounts/${accountName}/branches`,
    {
      branchName: versionName,
    },
    { 'x-github-token': gitHubToken }
  )

  console.log('createVersion response', response)
  return response
}

export const createCommit = async (
  gitHubToken,
  accountName,
  versionName,
  env,
  filePath,
  fileContent,
  message
) => {
  const response = await http.post(
    `${config.apiUrl}/accounts/${accountName}/commit`,
    {
      targetBranch: versionName,
      env,
      filePath,
      fileContent,
      message,
    },
    { 'x-github-token': gitHubToken }
  )

  return response
}

export const fetchPartnerCerts = async (name, env) => {
  const partnerCerts = await http.get(`${config.apiUrl}/certsAS2/${name}/${env}`)
  console.log(partnerCerts)
  return partnerCerts
}

export const uploadPartnerCert = async (fileName, content, name, env) => {
  const response = await http.post(`${config.apiUrl}/certsAS2/${name}/${env}`, {
    fileName,
    content,
  })
  console.log(response)
  return response
}

// This API does not currently support an account argument
// todo: Specify account when the feature is available
export const fetchAccountCerts = async () => {
  const certs = await http.get(config.sshCertApiUrl)
  return certs
}

export const createAccountCert = async (fields) => {
  const results = await http.post(
    `${config.sshCertApiUrl}`,
    {
      mft_account_name: fields.accountName,
      partner_name: fields.partnerName,
      partner_email: fields.partnerEmail,
      endpoint_type: fields.endpointType,
      executor_email: fields.executorEmail,
    },
    {
      'Content-Type': 'application/json',
    }
  )
  return results
}
