import React from 'react'
import { Button, Select, Table, TextInput } from '@nike/epic-react-ui'
import { HelpText } from '__components'

// Dropdown encourages use of names the MFT team wants to standardize on
const namesForDropdown = ['DEPARTMENT', 'NIKE_CONTACT', 'PARTNER_CONTACT']

const reducer = (state, action) => {
  const newState = { ...state }
  let newRow, newCell

  switch (action.type) {
    case 'addRow':
      newRow = { name: '', value: '' }
      newState.fields.data.push(newRow)
      return { ...newState }
    case 'cell':
      newCell = state.fields.data[action.rowIndex]
      newCell[action.nameOrValue] = action.value
      newState.fields.data[action.rowIndex] = newCell
      return { ...newState }
    default:
      return state
  }
}

export default function CustomVarsForm({ data, onSave, EditControls }) {
  const [state, dispatch] = React.useReducer(reducer, {
    errors: {},
    fields: {
      data,
    },
    touched: {},
  })

  const { fields } = state

  const renderNameField = React.useCallback(
    (cell) => {
      // Options for Name dropdown should not include names that are already used
      const namesInUse = fields.data.map((v) => v.name).filter((n) => n !== '')
      const options = namesForDropdown
        .filter((name) => !namesInUse.includes(name))
        .map((name) => ({ label: name, value: name }))
      return (
        <>
          <Select
            isCreatable
            value={{ label: cell.value, value: cell.value }}
            options={options}
            onChange={(option) =>
              dispatch({
                type: 'cell',
                rowIndex: cell.row.index,
                nameOrValue: cell.column.id,
                value: option.value,
              })
            }
          />
          {cell.value === '' ? (
            <HelpText>Choose a name from the dropdown or type a new one.</HelpText>
          ) : null}
        </>
      )
    },
    [dispatch, fields.data]
  )

  const renderValueField = React.useCallback(
    (cell) => (
      <TextInput
        value={cell.value}
        placeholder='Value'
        onChange={(e) =>
          dispatch({
            type: 'cell',
            rowIndex: cell.row.index,
            nameOrValue: cell.column.id,
            value: e.target.value,
          })
        }
      />
    ),
    [dispatch]
  )

  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      Cell: renderNameField,
    },
    {
      accessor: 'value',
      Header: 'Value',
      Cell: renderValueField,
    },
  ]

  const addBlankRow = React.useCallback(() => dispatch({ type: 'addRow' }), [dispatch])
  const handleSave = React.useCallback(() => {
    // First exclude rows with blank name or value
    const rows = fields.data.filter((f) => f.name && f.value)
    // Then create the objects required by JSON schema (i.e. {<name>: <value>})
    onSave(rows.map((row) => ({ [row.name]: row.value })))
  }, [fields, onSave])

  return (
    <>
      <EditControls onSave={handleSave} />
      <Table
        columns={columns}
        data={fields.data}
        withSearchBar
        searchBarPlaceholder='Search variables...'
      />
      <Button onClick={addBlankRow} small>
        Add
      </Button>
    </>
  )
}
