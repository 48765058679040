import config from '__config'
import { http } from '__util'

function url() {
  const parts = [config.apiUrl].concat(...arguments)
  return parts.join('/')
}

const mod = {
  url,
  list: (collection, params) => http.get(url(collection) + '?' + new URLSearchParams(params)),
  get: (collection, id) => http.get(url(collection, id)),
  delete: (collection, id) => http.delete(url(collection, id)),
  create: (collection, data) => http.post(url(collection), data),
  save: (collection, id, data, headers) => http.put(url(collection, id), data, headers),
  modify: (collection, id, verb, data) => http.put(url(collection, id, verb), data),
  uploadToS3: (url, formData) => http.post(url, formData),
  getFileDetails: (env, transferId) => http.get(url(`files/details/${env}/${transferId}`)),
  resubmitFiles: (env, transfers) => http.post(url('files'), { env, transfers }),
}

export default mod
