import merge from 'deepmerge'

const narkPreprod = {
  platformId: '==nUmGjjzibg4unsHDw5cal',
  url: 'https://api-preprod.nark.nikecloud.com/v1',
}
const narkProd = {
  platformId: '==RZ7WjZcXvK5s3x6RckPMO',
  url: 'https://api.nark.nikecloud.com/v1',
}

const determineEnvConfig = (url) => {
  const { hostname, origin } = url
  const isInConsole = hostname.includes('platforms.nike.com')
  const basePath = isInConsole ? `/mft` : ``

  const base = {
    // Node.js REST API - https://github.com/nike-axway-mft/mft-portal-api
    apiUrl: 'https://um8bujnlbd.execute-api.us-west-2.amazonaws.com/sandbox/v1',
    sshCertApiUrl: 'https://mft-api.mftaws.nike.com/selfservice/sshkeygen',
    appName: 'MFT',
    appFullName: 'Managed File Transfer',
    platformId: 'ecf1cbfd-5abf-4d42-886b-5fcbc2daff80',
    basePath,
    gitHubAuth: {
      // Configured in GitHub `nike-ep-mft` org > Settings > Developer > oAuth Apps.
      // The corresponding secret for each of these front end clients must be available
      // to the API lambda in order to complete the oAuth flow.
      authUrl: 'https://github.com/login/oauth/authorize',
      clientId: '', // to be determined below
    },
    isProd: false,
    nark: narkPreprod,
    oAuth: {
      // Configured in https://developer.niketech.com
      clientId: 'nike.niketech.mft',
      redirectUri: `${origin}${basePath}`,
      responseType: 'authorization_code',
      scope: 'profile openid email',
      autoRenew: true,
    },
  }

  const dev = merge(base, {
    env: 'dev',
    isLocal: hostname.toLowerCase().includes('localhost'),
    gitHubAuth: {},
  })
  const preprod = merge(base, {
    env: 'preprod',
    apiUrl: 'https://5e8pmx4jz8.execute-api.us-west-2.amazonaws.com/nonprod/v1',
  })

  const prod = merge(base, {
    env: 'prod',
    isProd: true,
    apiUrl: 'https://uyunhpis7b.execute-api.us-west-2.amazonaws.com/prod/v1',
    nark: narkProd,
  })

  const test = merge(base, {
    env: 'test',
    gitHubAuth: {
      clientId: '13355791466c69711a31',
    },
  })

  const host = (x) => hostname.includes(x)
  switch (true) {
    // unit tests
    case process.env.NODE_ENV === 'test':
      return test
    // Dev URLs
    case host('localhost'):
      dev.gitHubAuth.clientId = '13355791466c69711a31'
      return dev
    case host('sandbox-portal.mftaws.nike.com'):
      dev.gitHubAuth.clientId = '7c1437c1be7308258db9'
      return dev
    case host('mft-dev.cx-platform-test.nikecloud.com'):
      dev.gitHubAuth.clientId = '5864e1db7b8063136fe8'
      return dev
    case host('console-dev.platforms.nike.com'):
      dev.gitHubAuth.clientId = '887d9d4b54d3fb708d68'
      return dev
    // Preprod / nonprod URLs
    case host('nonprod-portal.mftaws.nike.com'):
      preprod.gitHubAuth.clientId = 'bccfa34618d4ec0d6a3b'
      return preprod
    case host('mft-preprod.cx-platform-test.nikecloud.com'):
      preprod.gitHubAuth.clientId = '2dc7583f6743e27a4379'
      return preprod
    case host('console-nonprod.platforms.nike.com'):
      preprod.gitHubAuth.clientId = '1b55d0df8ac95a3e945f'
      return preprod
    // Prod URLs
    case host('portal.mftaws.nike.com'):
      prod.gitHubAuth.clientId = '5114592511f204334ace'
      return prod
    case host('mft.cx-platform-prod.nikecloud.com'):
      prod.gitHubAuth.clientId = '9cf247bd01a34748c7b7'
      return prod
    case host('console.platforms.nike.com'):
      prod.gitHubAuth.clientId = 'eccd013d876279eb5432'
      return prod
    default:
      return test
  }
}
const url = new URL(window.location)
// NOTE: for example, 'https://console-dev.platforms.nike.com:8080/mft/files/?query=1&string=2'
/*
  URL({
    href: 'https://console-dev.platforms.nike.com/mft/files/?query=1&string=2',
    origin: 'https://console-dev.platforms.nike.com:8080',
    protocol: 'https:',
    username: '',
    password: '',
    host: 'console-dev.platforms.nike.com:8080',
    hostname: 'console-dev.platforms.nike.com',
    port: '8080',
    pathname: '/mft/files/',
    search: '?query=1&string=2',
  })
*/

const config = determineEnvConfig(url)

export default config
