import React from 'react'
import { Button, Spinner, Table } from '@nike/epic-react-ui'
import { Collapsible, ExtraTableControls } from '__components'
import { getAccount } from '__accounts/api.js'
import { cannotEditDeployed } from '__accounts/VersionSwitcher'
import { TruncatedText } from '__util/strings'
import { GitHubContext } from '__src/github/GitHubAuth'
import DetailsLink from './common/DetailsLink.js'
import EditLink from './common/EditLink.js'
import SiteWizard from './sites/SiteWizard.js'
import { deserializeSite, protocolToWord } from './sites/serialize.js'
import DetailsContext from './DetailsContext.js'
import SiteCopyLink from './sites/SiteCopyLink.js'

const triggerStyle = { margin: '1em auto', width: 'fit-content' }

const AddButton = ({ disabled, loading, onClick }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      disabledTip={{ message: cannotEditDeployed }}
      small
    >
      {loading ? <Spinner color='#fff' /> : 'Add a Site'}
    </Button>
  )
}

const initialState = {
  // Store the site being edited in state to pass it to the wizard below
  editSite: {},
  mainConfigs: {},
  loading: false,
}

function sitesReducer(state, action) {
  switch (action.type) {
    case 'loading':
      return { ...state, loading: action.isLoading }
    case 'loaded':
      if (action.mainConfigs) {
        return {
          ...state,
          loading: false,
          editSite: action.editSite,
          mainConfigs: action.mainConfigs,
        }
      } else {
        return { ...state, loading: false, editSite: action.editSite }
      }
    default:
      return state
  }
}

export default function Sites({ isEditing, setEditing, cancelEditing }) {
  const details = React.useContext(DetailsContext)
  const github = React.useContext(GitHubContext)
  const token = github.getToken()
  const { name, canEdit, commitChanges, env, envConfig } = details
  const sites = React.useMemo(() => envConfig?.sites || [], [envConfig])

  const [state, dispatch] = React.useReducer(sitesReducer, initialState)
  const { editSite, loading, mainConfigs } = state

  const openWizard = React.useCallback(
    (editSite) => {
      dispatch({ type: 'loading', isLoading: true })
      getAccount(token, name, 'main')
        .then((response) => {
          dispatch({ type: 'loaded', editSite, mainConfigs: response.configs })
          // Disable other edit links immediately; loading can take a while
          setEditing()
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    [token, name, setEditing]
  )

  const resetWizard = React.useCallback(() => {
    dispatch({ type: 'loaded', editSite: {} })
    cancelEditing()
  }, [cancelEditing])

  const getDeployedSites = React.useCallback(() => {
    return mainConfigs[env]?.sites || []
  }, [env, mainConfigs])

  const columns = React.useMemo(() => {
    return [
      {
        accessor: 'site_name',
        Header: 'Name',
        Cell: ({ value }) => {
          const site = sites.find((site) => site.site_name === value) || {}
          return (
            <DetailsLink title={site && site.site_name} getFields={() => deserializeSite(site)} />
          )
        },
      },
      {
        accessor: 'contact',
        Header: 'Contact',
        Cell: ({ value }) => <TruncatedText maxChars={50} text={value} />,
      },
      {
        accessor: 'transfer_type',
        Header: 'Type',
      },
      {
        accessor: 'protocol',
        Header: 'Protocol',
        Cell: ({ value }) => protocolToWord(value),
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row }) => {
          const site = sites.find((site) => site.site_name === row?.cells?.[0].value) || {}
          return (
            <div className='columns'>
              <EditLink item={site} openWizard={openWizard} show={!loading && !isEditing} />
              <SiteCopyLink env={env} site={site} show={!loading && !isEditing} />
            </div>
          )
        },
      },
    ]
  }, [env, isEditing, loading, openWizard, sites])

  return (
    <>
      {!isEditing && (
        <ExtraTableControls>
          <AddButton disabled={!canEdit} loading={loading} onClick={() => openWizard({})} />
        </ExtraTableControls>
      )}
      <Table
        className='sites-table'
        columns={columns}
        data={sites}
        withSearchBar
        searchBarPlaceholder='Search sites...'
      />

      {canEdit ? (
        <>
          <Collapsible
            open={isEditing}
            trigger={
              <AddButton disabled={isEditing} loading={loading} onClick={() => openWizard({})} />
            }
            triggerWhenOpen={
              <Button onClick={() => resetWizard()} inverse small>
                {loading ? <Spinner /> : 'Cancel '}
              </Button>
            }
            triggerStyle={triggerStyle}
            triggerTagName='div'
          >
            {isEditing && (
              <SiteWizard
                // debug
                commitChanges={commitChanges}
                env={env}
                envConfig={envConfig}
                site={editSite}
                getDeployedSites={getDeployedSites}
              />
            )}
          </Collapsible>
        </>
      ) : (
        <div style={triggerStyle}>
          <Button disabled disabledTip={{ message: cannotEditDeployed }} small>
            Add
          </Button>
        </div>
      )}
    </>
  )
}
