import React from 'react'
import { Label, Select, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText, ValidationError } from '__components'
import { required, string } from '__util/forms/validators.js'
import { findValue, objToSelectOptions } from '__util/select.js'
import { SiteWizardContext } from './SiteWizard.js'
import { systemNameOptions } from './serialize.js'
import Protocol from './fields/Protocol.js'
import Role from './fields/Role.js'
import { Link } from 'raviger'

export default function NameStep(props) {
  const { registerStep, setField, state, validateStep, StepButtons } =
    React.useContext(SiteWizardContext)
  const { errors, fields } = state || { errors: {}, fields: {} }
  const { isStepValid } = props.isActive ? validateStep(props.name, state) : { isStepValid: true }

  React.useEffect(() => {
    registerStep(props.name, {
      jobName: [
        required((v, state) => state.fields.protocol !== 'as2' && state.fields.role === 'source'),
        string({
          regex: /^[a-zA-Z0-9_-]+$/,
          regexExplanation: 'Only alphanumeric, dash (-) and underscore (_) characters.',
        }),
      ],
      systemName: [required()],
      otherSystemName: [required((v, state) => fields.systemName === 'other')],
    })
  }, [fields.systemName, props.name, registerStep])

  const capRole = fields.role?.[0].toUpperCase() + fields.role.slice(1)
  const systemNameHelpText = () => {
    if (fields.protocol === 'as2') {
      return 'External partner AS2 connection to which this transfer Site will make AS2 connection to send and receive files.'
    }
    if (fields.role === 'source') {
      return 'System/server from which this transfer site will pull files.'
    } else {
      return 'System/server to which this transfer site will push files.'
    }
  }

  return (
    <>
      <Protocol name='protocol' stepName={props.name} />
      <Role name='role' stepName={props.name} />
      {fields.role === 'source' && fields.protocol !== 'as2' && (
        <Field>
          <Label label='Autosys/Airflow Job Name' required={fields.role === 'source'}>
            <HelpText>
              If a job is associated with this transfer, please provide it now. Autosys and Airflow
              are Nike standard workflow processing platforms which kick off the MFT transfer. The
              job should be setup by your Batch coordinator. If you need to contact a batch
              coordinator for your business group please use the following{' '}
              <Link
                href='https://confluence.nike.com/display/NGP/Batch+Coordinator+List'
                target='_blank'
                rel='noopener noreferrer'
              >
                list
              </Link>{' '}
              as a starting point, although it may be out of date for your org.
            </HelpText>
            <TextInput
              hasErrors={errors.jobName}
              value={fields.jobName || ''}
              onChange={(e) => setField('jobName', e.target.value)}
            />
            <ValidationError message={errors.jobName} />
          </Label>
        </Field>
      )}
      <Field>
        <Label label={`${capRole} System`} required>
          <HelpText>{systemNameHelpText()}</HelpText>
          <Select
            onChange={(option) => setField('systemName', option.value)}
            options={objToSelectOptions(systemNameOptions)}
            value={findValue(systemNameOptions, fields.systemName)}
          />
        </Label>
      </Field>
      {fields.systemName === 'other' && (
        <Field>
          <Label label='Other System Name'>
            <TextInput
              hasErrors={errors.otherSystemName}
              onChange={(e) => setField('otherSystemName', e.target.value)}
              value={fields.otherSystemName || ''}
            />
            <ValidationError message={errors.otherSystemName} />
          </Label>
        </Field>
      )}
      <Field>
        <Label label='Name'>
          <HelpText>This is the name that will be used to identify this site.</HelpText>
          <TextInput disabled hasErrors={errors.site_name} value={fields.site_name} />
          <ValidationError message={errors.site_name} />
        </Label>
      </Field>
      <StepButtons
        {...props}
        nextButtonDisabledMessage={!isStepValid && 'Please complete this step before moving on'}
      />
    </>
  )
}
