import React from 'react'
import { Field, FormSet, HelpText } from '__components'
import {
  formSetValidator,
  integer,
  listOfEmails,
  required,
  onlyAlphaNumAndUnderscores,
  string,
} from '__util/forms/validators.js'
import { SubWizardContext } from './SubWizard.js'
import FilterCondition from './fields/FilterCondition.js'
import BusinessUnit from './formSetFields/BusinessUnit.js'
import FailureEmail from './formSetFields/FailureEmail.js'
import FilterRule from './formSetFields/FilterRule.js'
import SimpleRouteName from './formSetFields/SimpleRouteName.js'
import Initiated from './formSetFields/Initiated.js'

import PullTargetAccount from './formSetFields/PullTargetAccount.js'
import PullTargetDir from './formSetFields/PullTargetDir.js'
import PullTargetFileCollision from './formSetFields/PullTargetFileCollision.js'

import PushTargetAccount from './formSetFields/PushTargetAccount.js'
import PushTargetSite from './formSetFields/PushTargetSite.js'
import PushTargetDir from './formSetFields/PushTargetDir.js'
import PushFailureRetryCount from './formSetFields/PushFailureRetryCount.js'
import PushFailureRetryInterval from './formSetFields/PushFailureRetryInterval.js'
import PushMaxParallelConnections from './formSetFields/PushMaxParallelConnections.js'

import Rename from './formSetFields/Rename.js'
import RenameActionOnStepFailure from './formSetFields/RenameActionOnStepFailure.js'
import RenameTargetFilename from './formSetFields/RenameTargetFilename.js'

import ActionOnStepFailure from './formSetFields/ActionOnStepFailure.js'

const endsWithSlash = /^.+\/$/

const ifPull = (v, state, { formIndex }) =>
  state.fields.simple_routes[formIndex].initiated === 'pull'
const ifPush = (v, state, { formIndex }) =>
  state.fields.simple_routes[formIndex].initiated === 'push'
const ifPushAndS3Site = (v, state, { formIndex }) => {
  const formValues = state.fields.simple_routes[formIndex]
  const { initiated, push_target_site } = formValues
  return initiated === 'push' && push_target_site && push_target_site.includes('S3')
}

const routingSchema = {
  simple_route_name: {
    component: SimpleRouteName,
    validators: [required(), onlyAlphaNumAndUnderscores()],
  },
  business_unit: {
    component: BusinessUnit,
    validators: [required()],
  },
  filter_rule: {
    component: FilterRule,
    validators: [required()],
  },
  failure_email: {
    component: FailureEmail,
    validators: [
      listOfEmails(),
      // Future: do not allow LST-MFT@nike.com
      // string({
      //   regex: /^(?!.*?\blst-mft@nike\.com\b).*/i,
      //   regexExplanation: 'LST-MFT@nike.com is not allowed.',
      // }),
    ],
  },
  initiated: {
    component: Initiated,
    initial: 'push',
    validators: [],
  },

  // rename fields
  rename: {
    component: Rename,
    initial: false,
    validators: [],
  },
  target_filename: {
    component: RenameTargetFilename,
    // eslint-disable-next-line
    initial: '${basename(currentfulltarget)}.dat',
    validators: [],
  },
  // Not currently used or part of MVP
  // filter_filename: {
  //   component: RenameFilterFilename,
  //   validators: [],
  // },
  rename_action_on_step_failure: {
    component: RenameActionOnStepFailure,
    initial: 'FAIL',
    validators: [],
  },

  // send_to_partner
  push_target_account: {
    component: PushTargetAccount,
    validators: [required(ifPush)],
  },
  push_target_site: {
    component: PushTargetSite,
    validators: [required(ifPush)],
  },
  push_target_dir: {
    component: PushTargetDir,
    initial: '', // By ensuring this is a string, avoids a string validation error when the field is hidden
    validators: [
      string({ regex: endsWithSlash, regexExplanation: 'Must end with a slash (/).' }),
      required(ifPushAndS3Site),
    ],
  },
  push_failure_retry_count: {
    component: PushFailureRetryCount,
    initial: 3,
    validators: [integer({ min: 1, max: 20 })],
  },
  push_failure_retry_interval: {
    component: PushFailureRetryInterval,
    initial: 3,
    validators: [integer()],
  },
  push_max_parallel_connections: {
    component: PushMaxParallelConnections,
    initial: '0',
    validators: [integer()],
  },

  // send_to_account fields
  pull_target_account: {
    component: PullTargetAccount,
    validators: [required(ifPull)],
  },
  pull_target_dir: {
    component: PullTargetDir,
    validators: [required(ifPull)],
  },
  pull_target_file_collision: {
    component: PullTargetFileCollision,
    initial: 'FAIL',
    validators: [required(ifPull)],
  },

  // common to send_to_account, and send_to_partner
  action_on_step_failure: {
    component: ActionOnStepFailure,
    initial: 'FAIL',
    validators: [],
  },
}
const routeFields = {}
const validators = {}
const initialValues = {}
Object.entries(routingSchema).forEach(([name, field]) => {
  routeFields[name] = field.component
  validators[name] = field.validators
  initialValues[name] = field.initial
})

const simpleRoutesValidator = formSetValidator(validators)

export default function RoutingStep(props) {
  const { registerStep, setField, state, StepButtons } = React.useContext(SubWizardContext)
  const { errors, fields } = state

  React.useEffect(() => {
    registerStep(props.name, {
      simple_routes: [simpleRoutesValidator],
    })
  }, [props.name, registerStep])

  const setRoute = React.useCallback(
    (routes, whatChanged, changedFormIndex, changedFieldName) => {
      setField('simple_routes', routes, { whatChanged, changedFormIndex, changedFieldName })
    },
    [setField]
  )

  return (
    props.isActive && (
      <>
        {fields.nikeInitiated ? (
          <>
            <FilterCondition stepName={props.name} />

            <HelpText>Add one or more routes.</HelpText>
            <FormSet
              addLabel='Add Another Route'
              errors={errors.simple_routes}
              fields={routeFields}
              fieldLayout='block'
              formTitle='Route'
              initialValues={initialValues}
              isCollapsible={true}
              onChange={setRoute}
              values={fields.simple_routes}
            />
          </>
        ) : (
          <Field>
            <p>Routing is only applicable to Nike-intiated transfers.</p>
          </Field>
        )}

        <StepButtons {...props} />
      </>
    )
  )
}
