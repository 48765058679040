import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { Field } from '__components'
import { objToSelectOptions } from '__util/select.js'
import { SiteWizardContext } from '../SiteWizard.js'
import { transferModeOptions } from '../serialize.js'
import { useRealOrMockContext } from '__util/test-helpers'

export default function TransferMode({ name, stepName }) {
  const { registerField, setField, state } = useRealOrMockContext(SiteWizardContext)

  React.useEffect(() => registerField(stepName, name, [], 'auto'), [name, registerField, stepName])

  return (
    <Field>
      <Label label='Transfer mode' />
      <RadioGroup
        list={objToSelectOptions(transferModeOptions)}
        onChange={(value) => setField(name, value)}
        value={state.fields[name] || ''}
      />
    </Field>
  )
}
