import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText, ValidationError } from '__components'
import { required, string } from '__util/forms/validators.js'
import { useRealOrMockContext } from '__util/test-helpers'

export default function SystemMetadata({ name, stepName }) {
  const { registerField, setField, state } = useRealOrMockContext(SiteWizardContext)
  const { errors, fields } = state

  React.useEffect(
    () =>
      registerField(stepName, name, [
        required(
          (v, state) => state.fields.role === 'target' && state.fields.s3_bucket_access === 'bucket'
        ),
        string({
          regex: /^[acl=]/,
          regexExplanation: 'must contain &ldquo;acl=&rdquo; at the beginning of the value',
        }),
      ]),
    [registerField, name, stepName]
  )

  return (
    fields.role === 'target' && (
      <Field>
        <Label label='SystemMetadata' required={state.fields.s3_bucket_access === 'bucket'}>
          <HelpText>
            ACL and cononical ID of the AWS account separated by &ldquo;\n&rdquo; as a string (not
            newline). You can add additional System metadata for S3 here as well. Example:{' '}
            <code>
              acl=bucket-owner-full-control\nx-amz-grant-full-control=id=eae46279fd60baa658017f9f66f1a3a891acaad712b3cc2832d8073911afa4a6,emailAddress=\&quot;Lst-DigitalTech.NGAP.Alerts@nike.com
            </code>
          </HelpText>
          <TextInput
            hasErrors={errors[name]}
            onChange={(e) => setField(name, e.target.value)}
            value={fields[name] || ''}
          />
          <ValidationError message={errors[name]} />
        </Label>
      </Field>
    )
  )
}
