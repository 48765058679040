import React from 'react'
import { Label, Select, Spinner } from '@nike/epic-react-ui'
import { getAccount } from '__accounts/api'
import { ImproperValueWarning, ValidationError } from '__components'
import { GitHubContext } from '__src/github/GitHubAuth'
import { findValue, listToSelectOptions } from '__util/select.js'
import { SubWizardContext } from '../SubWizard.js'

const initialState = {
  loading: false,
  // Store subscription_folder values per account to avoid having to refetch them
  folderNamesByAccount: {
    // [account name]: [subscription_folder values in that account, the current environment and main branch]
  },
}

function targetSubReducer(state, action) {
  let folderNamesByAccount
  switch (action.type) {
    case 'waiting':
      return { ...state, loading: action.why }
    case 'saveFolderNamesForAccount':
      folderNamesByAccount = { ...state.folderNamesByAccount }
      folderNamesByAccount[action.targetAccount] = action.folderNames
      return { loading: '', folderNamesByAccount }
    default:
      return state
  }
}

export default function PullTargetDir({ error, formValues, onChange, value }) {
  const github = React.useContext(GitHubContext)
  const token = github.getToken()
  const wizard = React.useContext(SubWizardContext)
  const env = wizard.state.env
  const { initiated: initiatedBy, pull_target_account: targetAccount } = formValues
  const [state, dispatch] = React.useReducer(targetSubReducer, initialState)

  React.useEffect(() => {
    async function fetchSubs() {
      dispatch({ type: 'waiting', why: 'fetchingSubs' })
      getAccount(token, targetAccount, 'main').then((response) => {
        const envConfig = response.configs[env] || {}
        const folderNames = envConfig?.subscriptions?.map((sub) => sub.subscription_folder) || []
        dispatch({ type: 'saveFolderNamesForAccount', targetAccount, folderNames })
      })
    }

    if (initiatedBy === 'pull' && targetAccount && !state.folderNamesByAccount[targetAccount]) {
      fetchSubs()
    }
  }, [env, initiatedBy, onChange, state.folderNamesByAccount, targetAccount, token, value])

  let options = []
  if (targetAccount) {
    const folderNames = state.folderNamesByAccount[targetAccount] || []
    options = listToSelectOptions(folderNames)
  }
  const option = findValue(options, value)

  return (
    formValues.initiated === 'pull' && (
      <Label key='ATD' label='Target Directory' required>
        {state.loading ? (
          <Spinner />
        ) : (
          <>
            <Select
              hasErrors={error}
              options={options}
              onChange={onChange}
              value={findValue(options, value)}
            />
            {targetAccount && options.length === 0 && (
              <ValidationError message={'The selected target account has no subscriptions.'} />
            )}
            <ImproperValueWarning
              show={option === null}
              value={value}
              reason='It is not one of the available options.'
            />
          </>
        )}
      </Label>
    )
  )
}
