import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, ValidationError } from '__components'
import { required, string, sunsettingFtpsHostAndUsername } from '__util/forms/validators.js'
import { SiteWizardContext } from '../SiteWizard.js'
import { useRealOrMockContext } from '__util/test-helpers'

const validUsername = () =>
  string({
    regex: /((^[a-zA-Z0-9_.]+([^.])$)|(^[a-zA-Z0-9_]$))/,
    regexExplanation: 'Only alphanumeric characters, underscores and dots. Cannot end with a dot',
  })

export default function Username({ name, stepName }) {
  const { registerField, setField, state } = useRealOrMockContext(SiteWizardContext)
  const { errors, fields } = state

  React.useEffect(
    () =>
      registerField(stepName, name, [required(), validUsername(), sunsettingFtpsHostAndUsername()]),
    [registerField, name, stepName]
  )

  return (
    <Field>
      <Label label='Username' required>
        <TextInput
          hasErrors={errors[name]}
          onChange={(e) => setField(name, e.target.value)}
          value={fields[name] || ''}
        />
        <ValidationError message={errors[name]} />
        {fields.site_name === 'FTPS' && (
          <div>
            Nike MFT is sunsetting AD users: a.prdmftnetapp, a.qamftnetapp, a.devmftnetapp.
            <br />
            Any new or edited transfers using these AD groups for FTPS sever connections will be
            rejected with a request for rework.
            <br />
            Teams will be responsible to create and use their own AD group.
          </div>
        )}
      </Label>
    </Field>
  )
}
