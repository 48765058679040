import React from 'react'
import { Label, Select, Spinner } from '@nike/epic-react-ui'
import { AlertsContext, ImproperValueWarning, ValidationError } from '__components'
import { findValue, listToSelectOptions } from '__util/select.js'
import { GitHubContext } from '__src/github/GitHubAuth'
import { getAccount } from '__accounts/api'
import { SubWizardContext } from '../SubWizard.js'

const initialState = {
  loading: false,
  // Store sites per account to avoid having to refetch them
  sitesByAccount: {
    // [account name]: [sites in that account, the current environment and main branch]
  },
}

function targetSiteReducer(state, action) {
  let sitesByAccount

  switch (action.type) {
    case 'waiting':
      return { ...state, loading: action.why }
    case 'saveSitesForAccount':
      sitesByAccount = { ...state.sitesByAccount }
      sitesByAccount[action.targetAccount] = action.siteNames
      return { loading: '', sitesByAccount }
    default:
      return state
  }
}

export default function PushTargetSite({ error, formValues, onChange, value }) {
  const alerts = React.useContext(AlertsContext)
  const github = React.useContext(GitHubContext)
  const token = github.getToken()
  const wizard = React.useContext(SubWizardContext)
  const env = wizard.state.env
  const { initiated: initiatedBy, push_target_account: targetAccount } = formValues
  const [state, dispatch] = React.useReducer(targetSiteReducer, initialState)

  React.useEffect(() => {
    async function fetchSites() {
      dispatch({ type: 'waiting', why: 'fetchingSites' })
      getAccount(token, targetAccount, 'main')
        .then((response) => {
          const envConfig = response.configs[env] || {}
          let siteNames = envConfig?.sites?.map((site) => site.site_name) || []
          siteNames = siteNames.filter((name) => name.toLowerCase().includes('outbound'))
          dispatch({ type: 'saveSitesForAccount', targetAccount, siteNames })
        })
        .catch((error) => {
          alerts.add({
            message: 'Failed to fetch list of sites for Target Account field.',
            level: 'error',
            dismiss: true,
          })
        })
    }

    if (initiatedBy === 'push' && targetAccount && !state.sitesByAccount[targetAccount]) {
      fetchSites()
    }
  }, [alerts, env, initiatedBy, onChange, state.sitesByAccount, targetAccount, token, value])

  const siteNames = state.sitesByAccount[targetAccount] || []
  let options = []
  if (targetAccount) {
    options = listToSelectOptions(siteNames)

    if (siteNames.length === 0) {
      error = 'The selected target account has no target sites ("OUTBOUND" must be in the name).'
    }
  }

  const option = findValue(options, value)

  return (
    initiatedBy === 'push' && (
      <Label key='TargetSite' label='Target Site' required>
        {state.loading ? (
          <Spinner />
        ) : (
          <>
            <Select hasErrors={error} options={options} onChange={onChange} value={option} />
            <ValidationError message={error} />
            <ImproperValueWarning
              show={option === null}
              value={value}
              reason='It is not one of the available options.'
            />
          </>
        )}
      </Label>
    )
  )
}
