import React from 'react'
import { IconButton, NextGen } from '@nike/epic-react-ui'
import { cannotEditDeployed } from '__accounts/VersionSwitcher'
import DetailsContext from '__accounts/details/DetailsContext.js'

export default function EditLink({ item, openWizard, show }) {
  const details = React.useContext(DetailsContext)
  const { canEdit } = details
  return (
    show && (
      <span>
        {canEdit ? (
          <NextGen.Tooltip message='Edit site' position='left'>
            <IconButton bare iconSize='s' onClick={() => openWizard(item)} type='Settings' />
          </NextGen.Tooltip>
        ) : (
          <div className={'table-menu-tooltip'}>
            <IconButton bare iconSize='s' type='Settings' disabled />
            <div className={'tooltip-container'}>
              <div className={'custom-tooltip'}>{cannotEditDeployed}</div>
            </div>
          </div>
        )}
      </span>
    )
  )
}
