import React from 'react'
import DetailsContext from './DetailsContext.js'
import FileSystemDetails from './FileSystemDetails.js'
import FileSystemForm from './FileSystemForm.js'

export default function FileSystem({ isEditing, EditControls }) {
  const details = React.useContext(DetailsContext)
  const { env, envConfig, name } = details

  return isEditing ? (
    <FileSystemForm EditControls={EditControls} env={env} envConfig={envConfig} name={name} />
  ) : (
    <FileSystemDetails EditControls={EditControls} />
  )
}
