import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, NextGen, RadioGroup, Select, Spinner } from '@nike/epic-react-ui'
import { Field, FilePicker, HelpText, ValidationError } from '__components'
import { fetchPartnerCerts, uploadPartnerCert } from '__accounts/api.js'
import { partnerCertSourceOptions } from '../serialize.js'
import { findValue } from '__util/select.js'
import { required } from '__util/forms/validators.js'
import { useRealOrMockContext } from '__util/test-helpers'

export default function PartnerAS2CertName({ stepName }) {
  const { registerField, setField, state } = useRealOrMockContext(SiteWizardContext)
  const { env, envConfig, errors, fields } = state
  const {
    account: { account_name },
  } = envConfig

  const [fetchPartnerCertLoading, setFetchPartnerCertLoading] = React.useState(false)
  const [partnerCertOptions, setPartnerCertOptions] = React.useState([])
  const [partnerCertSource, setPartnerCertSource] = React.useState('upload')
  const [uploadPartnerCertLoading, setUploadPartnerCertLoading] = React.useState(false)
  const [uploadPartnerCertError, setUploadPartnerCertError] = React.useState(false)
  const [showUploadStatusModal, setShowUploadStatusModal] = React.useState(false)
  const [uploadedFileName, setUploadedFileName] = React.useState('')

  React.useEffect(() => {
    registerField(stepName, 'partner_as2_cert_name', [required()])
  }, [registerField, stepName])

  const callFetchPartnerCerts = React.useCallback(() => {
    setFetchPartnerCertLoading(true)
    fetchPartnerCerts(account_name, env)
      .then((response) => {
        const formattedOptions = response.map((certName) => ({
          value: certName,
          label: certName,
        }))
        setPartnerCertOptions(formattedOptions)
        setFetchPartnerCertLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setFetchPartnerCertLoading(false)
      })
  }, [account_name, env, setFetchPartnerCertLoading, setPartnerCertOptions])

  React.useEffect(() => {
    callFetchPartnerCerts()
  }, [callFetchPartnerCerts])

  const callUploadPartnerCert = React.useCallback(
    (file) => {
      const formatPartnerCertName = (name) => {
        const incs = []
        partnerCertOptions.map((cert) => {
          if (cert.value.toUpperCase().includes(`${account_name}_AS2_`)) {
            incs.push(parseInt(cert.value.match(/[^._]+(?=[^_]*$)/)[0]))
          }
          return true
        })
        const inc = String((incs.sort()[incs.length - 1] += 1)).padStart(3, '0')
        const ext = name.match(/\.[^.]+$/)?.[0] || ''

        return `${account_name}_AS2_${inc}${ext}`
      }

      setUploadPartnerCertLoading(true)
      setUploadedFileName(file?.name)
      setUploadPartnerCertError(false)

      uploadPartnerCert(file?.name, file, account_name, env)
        .then((response) => {
          const formattedPartnerCertName = formatPartnerCertName(file?.name)
          setField('partner_as2_cert_name', formattedPartnerCertName)
          setUploadPartnerCertLoading(false)
          setShowUploadStatusModal(true)
        })
        .catch((error) => {
          console.error(error)
          setUploadPartnerCertLoading(false)
          setUploadPartnerCertError(true)
          setShowUploadStatusModal(true)
        })
    },
    [
      setUploadPartnerCertLoading,
      setUploadPartnerCertError,
      setUploadedFileName,
      setShowUploadStatusModal,
      partnerCertOptions,
      setField,
      account_name,
      env,
    ]
  )

  const onClearUploadFile = () => {
    setUploadedFileName('')
    setUploadPartnerCertError(false)
  }

  const onCloseModal = () => {
    setShowUploadStatusModal(false)
  }

  return (
    <>
      <Field>
        <Label label='Partner AS2 Cert' required>
          <HelpText>
            Would you like to upload a new AS2 Partner certificate for this account/environment or
            is the certificate for this transfer already on the account?
          </HelpText>
          <RadioGroup
            list={partnerCertSourceOptions}
            onChange={(value) => setPartnerCertSource(value)}
            value={partnerCertSource}
          />
        </Label>
      </Field>
      {partnerCertSource === 'upload' && (
        <Field>
          <Label label='Upload Partner AS2 Cert' required />
          <FilePicker
            onSelect={(file) => callUploadPartnerCert(file)}
            loading={uploadPartnerCertLoading}
            onClear={onClearUploadFile}
            error={uploadPartnerCertError}
            value={uploadedFileName}
          />
          <ValidationError message={errors.partner_as2_cert_name} />
        </Field>
      )}
      {partnerCertSource === 'select' && (
        <>
          {fetchPartnerCertLoading ? (
            <Spinner large className='centered' />
          ) : (
            <Field>
              <Label label='Choose a Partner AS2 Cert' required>
                <HelpText>
                  These certs are also listed under the Certificates tab having Usage
                  &ldquo;partner&rdquo;.
                </HelpText>
                <Select
                  onChange={(option) => setField('partner_as2_cert_name', option.value)}
                  options={partnerCertOptions}
                  value={findValue(partnerCertOptions, fields.partner_as2_cert_name)}
                />
                <ValidationError message={errors.partner_as2_cert_name} />
              </Label>
            </Field>
          )}
        </>
      )}
      <NextGen.Modal show={showUploadStatusModal} onClose={onCloseModal} modalSize='md'>
        {uploadPartnerCertError ? (
          <p className='text-error'>Upload failed. Please try again or contact an MFT admin.</p>
        ) : (
          <>
            <p>This new cert has been named:</p>
            <p>
              <strong>{fields.partner_as2_cert_name}</strong>
            </p>
          </>
        )}
      </NextGen.Modal>
    </>
  )
}
