import React from 'react'
import { PrettyJSON } from '__components'
import { normalizeSiteField } from '../sites/serialize.js'

export default function ItemDetails({ fields }) {
  return (
    <>
      <table className='UnstyledTable ItemDetails'>
        <tbody>
          {Object.entries(fields).map(([name, value], index) => {
            const [newName, newValue] = normalizeSiteField(name, value)
            if (typeof value === 'object') {
              return (
                <tr key={`item-field-${index}`}>
                  <td>{newName}</td>
                  <td>
                    <PrettyJSON>{value}</PrettyJSON>
                  </td>
                </tr>
              )
            } else {
              return (
                <tr key={`item-field-${index}`}>
                  <td>{newName}</td>
                  <td>{newValue}</td>
                </tr>
              )
            }
          })}
        </tbody>
      </table>
    </>
  )
}
