import React, { Fragment } from 'react'
import DetailsContext from './DetailsContext.js'
import AuthTypeField from './fileSystem/AuthTypeField'
import CerberusPathField from './fileSystem/CerberusPathField'
import CertField from './fileSystem/CertField'
import UploadedCertField from './fileSystem/UploadedCertField'
import EndpointField from './fileSystem/EndpointField'
import RequiresAccessField from './fileSystem/RequiresAccessField'
import UserNameField from './fileSystem/UserNameField'
import WhitelistField from './fileSystem/WhitelistField'
import { getAccountLoginCerts, uploadLoginCert } from '../api'

const generateUserName = (account) => {
  const userNameSuffix = account.transfer_type === 'I' ? '' : '_0'
  return `mftuser_${account.account_name.toLowerCase()}${userNameSuffix}`
}

const generateEndpoint = (account, env, useWhitelist) => {
  const intOrExt = account.transfer_type === 'I' ? 'int' : 'ext'
  return `mft${intOrExt}${env}${useWhitelist ? '-wl' : ''}.mftaws.nike.com`
}

const reducer = (state, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'setAuthType':
      newState.authType = action.value
      newState.use_password = action.value === 'cert' ? false : true
      return { ...newState }
    case 'useWhitelist':
      return { ...newState, useWhitelist: action.value }
    case 'setUploadedCerts':
      return { ...newState, uploadedCerts: action.value }
    case 'setUploadedFile':
      return {
        ...newState,
        certName: action.fileName,
        newCertName: action.fileName,
        newCertContents: action.fileContents,
      }
    case 'setShowUploadField':
      return { ...newState, showUploadField: action.show, certName: action.certName }
    default:
      return state
  }
}

export default function FileSystemForm({ env, envConfig, EditControls, name }) {
  const { commitChanges } = React.useContext(DetailsContext)
  const user = envConfig.user || {}
  const certs = envConfig?.certs || []

  const [requiresAccess, setRequiresAccess] = React.useState(Object.keys(user).length > 0)
  const loginCert = certs.find((c) => c.cert_usage === 'login')
  const otherCerts = certs.filter((c) => c.cert_usage !== 'login')
  const [state, dispatch] = React.useReducer(reducer, {
    ...user,
    use_password: user.use_password === 'true', // Convert string "true" or "false" to bool
    authType:
      user.use_password === 'true' ? (user.cerberus_sdb_path ? 'cerberus' : 'cyberark') : 'cert',
    certName: (loginCert && loginCert.cert_name) || '',
    useWhitelist: user.endpoint?.includes('-wl') || false,
    uploadedCerts: [],
    newCertName: '',
    newCertContents: '',
    showUploadField: false,
  })
  const { certName, newCertName, newCertContents, showUploadField } = state

  const userName = generateUserName(envConfig.account)
  const endpoint = generateEndpoint(envConfig.account, env, state.useWhitelist)

  const fetchUploadedCerts = React.useCallback(
    (name, env) => {
      getAccountLoginCerts(name, env)
        .then((response) => {
          dispatch({
            type: 'setUploadedCerts',
            value: [...response],
          })
        })
        .catch((error) => {
          dispatch({
            type: 'setUploadedCerts',
            value: [],
          })
        })
    },
    [dispatch]
  )

  React.useEffect(() => {
    fetchUploadedCerts(name, env)
  }, [name, env, fetchUploadedCerts])

  const prepUserAndCertFileContents = React.useCallback(() => {
    if (requiresAccess) {
      const user = {
        use_password: state.use_password,
        user_name: userName,
        endpoint,
      }

      const certs = [...otherCerts]
      if (user.use_password === false) {
        certs.push({
          cert_usage: 'login',
          cert_name: certName || '',
        })
      }
      return { user, certs }
    } else {
      // clear the user.json file and remove login cert (if any) from certs.json
      return { user: {}, certs: otherCerts }
    }
  }, [certName, endpoint, otherCerts, state, requiresAccess, userName])

  const onSave = React.useCallback(async () => {
    const data = prepUserAndCertFileContents()
    if (showUploadField && newCertName && newCertContents) {
      await uploadLoginCert(newCertName, newCertContents, name, env)
    }

    await commitChanges('user.json', data.user, { refetchAccount: false })
    await commitChanges('certs.json', data.certs, { refetchAccount: true })
  }, [
    prepUserAndCertFileContents,
    showUploadField,
    newCertName,
    newCertContents,
    name,
    env,
    commitChanges,
  ])

  const recordUploadedFile = React.useCallback(
    (details) => {
      if (!details) {
        details = { fileName: '', fileContents: '' }
      }
      const { fileName, fileContents } = details
      dispatch({ type: 'setUploadedFile', fileName, fileContents })
    },
    [dispatch]
  )

  const authTypeOptions = [
    { label: 'Password - stored in CyberArk', value: 'cyberark' },
    { label: 'Password - stored in Cerberus', value: 'cerberus' },
    { label: 'Certificate', value: 'cert' },
  ]

  return (
    <>
      <EditControls onSave={onSave} />
      <RequiresAccessField onChange={(value) => setRequiresAccess(value)} value={requiresAccess} />
      {requiresAccess ? (
        <>
          <UserNameField value={userName} />
          {envConfig.account.transfer_type === 'E' && (
            <WhitelistField
              onChange={(value) => dispatch({ type: 'useWhitelist', value })}
              value={state.useWhitelist}
            />
          )}

          <EndpointField value={endpoint} />

          <AuthTypeField
            options={authTypeOptions}
            env={env}
            onChange={(value) => dispatch({ type: 'setAuthType', value })}
            value={state.authType}
          />
          {state.authType === 'cerberus' && (
            <CerberusPathField
              onChange={() => {
                console.log('unimplemented')
              }}
              value={user.cerberus_sdb_path}
            />
          )}
          {state.authType === 'cert' && (
            <Fragment>
              <UploadedCertField
                uploadedCertNames={state.uploadedCerts}
                selectedCertName={certName}
                onChange={({ value }) => {
                  if (value === 'other') {
                    dispatch({ type: 'setShowUploadField', show: true, certName: '' })
                  } else {
                    dispatch({ type: 'setShowUploadField', show: false, certName: value })
                  }
                }}
              />
              {showUploadField && (
                <CertField
                  accountName={envConfig.account.account_name}
                  env={env}
                  value={certName}
                  onChange={recordUploadedFile}
                />
              )}
            </Fragment>
          )}
          {/* Include the Cancel & Save buttons below the form as well, better UX. */}
          <EditControls onSave={onSave} />
        </>
      ) : null}
    </>
  )
}
