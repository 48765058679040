import React from 'react'
import { Label, Select } from '@nike/epic-react-ui'
import { Field, ValidationError } from '__components'
import {
  as2ProtocolOnlyForExternal,
  smbProtocolOnlyForInternal,
  sunsettingFtpsHostAndUsername,
} from '__util/forms/validators.js'
import { findValue, objToSelectOptions } from '__util/select.js'
import { SiteWizardContext } from '../SiteWizard.js'
import { useRealOrMockContext } from '__util/test-helpers'

const getProtocolOptions = (transferType) => {
  const options = {
    ssh: 'SFTP/SSH',
    ftps: 'FTPS',
    s3: 'S3',
    'azure-blob': 'Azure Blob',
  }

  if (transferType === 'E') {
    // Only sites internal to Nike support SMB
    options.as2 = 'AS2'
    return options
  }

  if (transferType === 'I') {
    // Only sites internal to Nike support SMB
    options.smb = 'SMB'
    return options
  }

  return options
}

export default function Protocol({ name, stepName }) {
  const { registerField, setField, state } = useRealOrMockContext(SiteWizardContext)
  const { errors, fields, isNew } = state
  const protocolOptions = React.useMemo(
    () => getProtocolOptions(fields.transfer_type),
    [fields.transfer_type]
  )

  React.useEffect(
    () =>
      registerField(
        stepName,
        name,
        [
          as2ProtocolOnlyForExternal(),
          smbProtocolOnlyForInternal(),
          sunsettingFtpsHostAndUsername(),
        ],
        'ssh'
      ),
    [registerField, name, protocolOptions, stepName]
  )

  return (
    <Field>
      <Label label='Protocol' required>
        <Select
          disabled={!isNew}
          hasErrors={errors[name]}
          onChange={(option) => setField(name, option.value)}
          options={objToSelectOptions(protocolOptions)}
          value={findValue(protocolOptions, fields[name])}
        />
        <ValidationError message={errors[name]} />
      </Label>
    </Field>
  )
}
