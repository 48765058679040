import React from 'react'
import { Icon } from '@nike/epic-react-ui'

function ImproperValueWarning({ show, value, reason = '' }) {
  const visible = typeof show === 'function' ? show(value) : show === true
  if (!visible) return null

  const maybe = (
    <span>
      This value <code>{value}</code> may be invalid. {reason}
    </span>
  )
  const empty = 'This value appears to be undefined. '
  const ignore = 'If this transfer is working as expected, please ignore this warning.'
  return (
    <div className='ImproperValueWarning'>
      <Icon type='AlertCircleFilled' size='s' />
      <span>
        {value ? maybe : empty}
        <br />
        {ignore}
      </span>
    </div>
  )
}

export default ImproperValueWarning
