import React from 'react'
import { Tooltip } from '__components'

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const capitalizeWords = (str) => {
  const words = str.split(' ')
  return words.map((word) => capitalize(word)).join(' ')
}

export const toCamelCaseCapitalized = (str) => {
  return capitalize(toCamelCase(str))
}

export const toCamelCase = (str) => {
  const camel = str
    .toLowerCase()
    .replace(/[^A-Za-z0-9\-_]/g, ' ') // toss non-desired characters
    .split(' ') // split into array of words
    .reduce((result, word) => result + capitalize(word.toLowerCase()))
  return camel
}

export const TruncatedText = ({ text = '', maxChars }) => {
  const shortened = maxChars && text.length > maxChars ? text.slice(0, maxChars) + '...' : text
  return shortened.length !== text.length ? (
    <Tooltip content={text}>
      <span>{shortened}</span>
    </Tooltip>
  ) : (
    <span>{text}</span>
  )
}
