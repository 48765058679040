import React from 'react'
import { selectIsLoggedIn, selectLoginComplete, useAuthStore } from '@nike/aegis-auth-react'
import IntegratedPlatform from '@nike/console-integrated-platform'

const IntegratedPlatformConnected = ({ accessToken, children, navBarConfig, platformId }) => {
  const platformConfig = {
    accessToken,
    hasAuthCheckFinished: useAuthStore(selectLoginComplete),
    isLoggedIn: useAuthStore(selectIsLoggedIn),
    // Slack icon in navbar links to #ep-cx-support
    slackURL: 'https://nikedigital.slack.com/archives/C01NF4Y0848',
    showConsoleNav: process.env.stage === 'production',
    useConsoleLoginScreen: false,
    techSolutionId: platformId,
    navBarConfig: { ...navBarConfig },
  }

  return <IntegratedPlatform {...platformConfig}>{children}</IntegratedPlatform>
}

export default IntegratedPlatformConnected
