import React from 'react'
import { useNark } from './nark'

// *NOTE: Hooks is not having equivalent for componentDidCatch. please refer to https://reactjs.org/docs/hooks-faq.html
class ErrorBoundary extends React.Component {
  state = { hasError: false }
  static nark = null
  static errorHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (window.location.hostname.toLowerCase().includes('localhost')) {
      console.log(e)
      return false
    }
    ErrorBoundary.logErrorToNark(e?.error?.message)
    return false
  }

  static logErrorToNark = (message, operation = 'error') => {
    const nark = ErrorBoundary.nark
    const payload = {
      feature: message,
      operation,
    }
    nark && nark.log(payload)
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }
  // TODO: When React version updates we can use functional component
  UNSAFE_componentWillMount() {
    window.addEventListener('error', ErrorBoundary.errorHandler, false)
  }
  componentWillUnmount() {
    window.removeEventListener('error', ErrorBoundary.errorHandler)
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong while rendering</h1>
    }
    return this.props.children
  }
}

export const withNarkHooksHOC = (Component) => {
  return (props) => {
    const nark = useNark()
    return <Component nark={nark} {...props} />
  }
}

export default withNarkHooksHOC(ErrorBoundary)
