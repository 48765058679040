import React from 'react'
import { Button, Tooltip } from '@nike/epic-react-ui'

// Some of these props are automatically passed in from react-step-wizard
export default function StepButtons(props) {
  const { currentStep, goToStep, nextButtonDisabledMessage, nextButtonLabel, totalSteps } = props
  const nextLabel = nextButtonLabel || 'Next'
  return (
    <div
      style={{
        display: 'flex',
        margin: '1em auto',
        width: '300px',
        justifyContent: 'space-between',
      }}
    >
      {currentStep === 1 ? null : (
        <Button onClick={() => goToStep(currentStep - 1)} inverse small>
          Back
        </Button>
      )}
      {nextButtonDisabledMessage ? (
        <Tooltip message={nextButtonDisabledMessage} position='bottom'>
          <Button disabled small>
            {nextLabel}
          </Button>
        </Tooltip>
      ) : currentStep === totalSteps ? null : (
        <Button onClick={() => goToStep(currentStep + 1)} small>
          {nextLabel}
        </Button>
      )}
    </div>
  )
}
