import React from 'react'
import { Button, Label, NextGen, Select, Spinner } from '@nike/epic-react-ui'
import { findValue, listToSelectOptions } from '__util/select.js'
import { Field, HelpText, ValidationError } from '__components'
import { required } from '__util/forms/validators.js'
import { SiteWizardContext } from '../SiteWizard.js'
import { createAccountCert, fetchAccountCerts } from '__accounts/api.js'
import SSHCertNameCreateForm from './SSHCertNameCreateForm'
import { useRealOrMockContext } from '__util/test-helpers'

export const commonCertNameOptions = [
  { value: 'INFA_SSH_KEY', label: 'Informatica - INFA_SSH_KEY' },
  { value: 'Fusion_SSH_KEY', label: 'Fusion - Fusion_SSH_KEY' },
  { value: 'EDI_SSH_KEY', label: 'Nike EDI - EDI_SSH_KEY' },
  {
    value: 'CONVERSE_EDI_SSH_KEY',
    label: 'Converse EDI - CONVERSE_EDI_SSH_KEY',
  },
]

export default function SSHCertName({ name, stepName }) {
  const {
    existingCertNames = [],
    registerField,
    setFields,
    state,
  } = useRealOrMockContext(SiteWizardContext)
  const { errors, fields, envConfig } = state
  const [isAccountCert, setIsAccountCert] = React.useState(false)
  const [sshCertOptions, setSshCertOptions] = React.useState([])
  const [listCertLoading, setListCertLoading] = React.useState(false)
  const [createCertLoading, setCreateCertLoading] = React.useState(false)
  const [newCertName, setNewCertName] = React.useState(false)
  const [errorCreateCert, setErrorCreateCert] = React.useState(false)
  const [showCreateKeyModal, setShowCreateKeyModal] = React.useState(false)

  const certNameOptions = React.useMemo(
    () =>
      listToSelectOptions(existingCertNames)
        .concat(commonCertNameOptions)
        .concat([{ value: 'other', label: 'Other' }]),
    [existingCertNames]
  )
  const accountName = envConfig.account.account_name

  React.useEffect(() => {
    registerField(stepName, name, [required((v, state) => state.fields.use_password === false)])
  }, [registerField, name, stepName])

  const callFetchAccountCerts = React.useCallback(() => {
    setListCertLoading(true)
    fetchAccountCerts()
      .then((response) => {
        setListCertLoading(false)
        const sshCertOptions = response.Result.filter((x) => x.account === accountName).map(
          (x) => ({
            value: x.sshkey,
            label: x.sshkey,
          })
        )
        setSshCertOptions(sshCertOptions)
      })
      .catch((error) => {
        console.error(error)
        setListCertLoading(false)
      })
  }, [accountName, setSshCertOptions])

  const callCreateAccountCert = React.useCallback(
    (fields) => {
      setCreateCertLoading(true)
      createAccountCert(fields)
        .then((response) => {
          const newCertName = response.private_key_name
          setCreateCertLoading(false)
          setNewCertName(newCertName)
          setSshCertOptions([...sshCertOptions, { value: newCertName, label: newCertName }])
          setFields({ selectedCertName: newCertName, ssh_cert_name: newCertName })
        })
        .catch((error) => {
          console.error(error)
          setCreateCertLoading(false)
          setErrorCreateCert(true)
        })
    },
    [setSshCertOptions, sshCertOptions, setFields]
  )

  const onCloseModal = () => {
    setShowCreateKeyModal(false)
    setNewCertName(false)
    setErrorCreateCert(false)
  }

  return (
    (fields.use_password === false || fields.use_password === 'false') && (
      <>
        <Field>
          <Label label='SSH Cert Name' className='SSHCertName' required>
            <HelpText>Either choose an existing cert name or provide a new one.</HelpText>
            <Select
              onChange={(selected) => {
                if (selected.value === 'other') {
                  setIsAccountCert(true)
                  setFields({ selectedCertName: '', ssh_cert_name: '' })
                  if (!sshCertOptions.length) callFetchAccountCerts()
                } else {
                  setIsAccountCert(false)
                  const certName = selected.value
                  setFields({ selectedCertName: selected.value, ssh_cert_name: certName })
                }
              }}
              options={certNameOptions}
              value={
                findValue(certNameOptions, isAccountCert ? 'other' : fields.selectedCertName) || ''
              }
              required
            />
            {isAccountCert && (
              <>
                {listCertLoading ? (
                  <Spinner large className='centered' />
                ) : (
                  <>
                    <HelpText>
                      The following certs have been previously used by this account.
                    </HelpText>
                    <Select
                      onChange={(selected) => {
                        const certName = selected.value
                        setFields({ selectedCertName: selected.value, ssh_cert_name: certName })
                      }}
                      options={sshCertOptions}
                      value={findValue(sshCertOptions, fields.selectedCertName) || ''}
                      style={{ 'margin-top': 20 }}
                      required
                    ></Select>
                    <Button onClick={() => setShowCreateKeyModal(true)} small>
                      Create New Cert
                    </Button>
                  </>
                )}
              </>
            )}
            <ValidationError message={errors[name]} />
          </Label>
        </Field>
        <NextGen.Modal show={showCreateKeyModal} onClose={onCloseModal} modalSize='md'>
          <SSHCertNameCreateForm
            createCertLoading={createCertLoading}
            newCertName={newCertName}
            errorCreateCert={errorCreateCert}
            isVisible={showCreateKeyModal}
            onClose={onCloseModal}
            onSubmit={callCreateAccountCert}
            accountName={accountName}
          />
        </NextGen.Modal>
      </>
    )
  )
}
