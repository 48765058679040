import React from 'react'

import { useNark, useNarkOnPageLoad } from '__util/nark'
import config from '__config'
import { HomePage as ConsoleHomePage } from '@nike/console-integrated-platform'

const pageName = 'home'
const { platformId } = config

const HomePage = ({ accessToken, ...props }) => {
  const nark = useNark()
  useNarkOnPageLoad({ nark, pageName })
  return <ConsoleHomePage techSolutionId={platformId} accessToken={accessToken} {...props} />
}

export default HomePage
