import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText, ValidationError } from '__components'
import { required, string } from '__util/forms/validators.js'
import { useRealOrMockContext } from '__util/test-helpers'

export default function DownloadObjectKey({ name, stepName }) {
  const { registerField, setField, state } = useRealOrMockContext(SiteWizardContext)
  const { errors, fields } = state

  React.useEffect(
    () =>
      registerField(stepName, name, [
        required((v, state) => state.fields.role === 'source'),
        string({
          regex: /^[^/].*\/$/,
          regexExplanation: 'Must include "/" at end of folder path. Must not start with "/"',
        }),
      ]),
    [registerField, name, stepName]
  )

  return (
    fields.role === 'source' && (
      <Field>
        <Label label='DownloadObjectKey' required>
          <HelpText>
            The folder path within this S3 bucket to download files. Example:{' '}
            <code>prod/ck/comms/datorama/nike_calendar/</code>
          </HelpText>
          <TextInput
            hasErrors={errors[name]}
            onChange={(e) => setField(name, e.target.value)}
            value={fields[name] || ''}
          />
          <ValidationError message={errors[name]} />
        </Label>
      </Field>
    )
  )
}
