import config from '__config'
import { AegisClient } from '@nike/aegis-auth-react'
const globalHeaders = {}

export function setGlobalHeader(name, value) {
  globalHeaders[name] = value
}

const oAuth = new AegisClient({ qa: !config.isProd, ...config.oAuth })

function handleLogin({ accessToken }) {
  setGlobalHeader('Authorization', 'Bearer ' + accessToken.accessToken)
}

oAuth.on('login:success', handleLogin)
oAuth.on('renew:access:success', handleLogin)

export default oAuth
