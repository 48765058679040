import React from 'react'
import { Link } from 'raviger'
import { Icon } from '@nike/epic-react-ui'
import { Collapsible, PrettyJSON } from '__components'

export default function AccountCreateResults({ results }) {
  const newAccountName = results?.steps?.createRepo?.result?.name || 'Something Went Wrong'
  const accountExists = newAccountName && results?.steps?.createTeam?.result

  return (
    <>
      <h2>{newAccountName}</h2>
      <ul className='AccountCreateResults unstyled'>
        {Object.entries(results.steps).map(([step, data]) => (
          <StepStatus stepName={step} data={data} key={`step-${step}`} />
        ))}
      </ul>
      <hr />
      {accountExists && <Link href={`/accounts/${newAccountName}`}>View Details</Link>}
    </>
  )
}

function StepStatus({ stepName, data }) {
  let cls,
    icon,
    details = ''

  if ('error' in data) {
    cls = 'color-error'
    icon = 'Close'
    details = <PrettyJSON>{data.error}</PrettyJSON>
  }
  if ('result' in data) {
    cls = 'color-success'
    icon = 'Check'
  }

  return (
    <li className={cls} style={{ padding: '4px' }}>
      <Collapsible
        trigger={
          <>
            <Icon type={icon} style={{}} /> {stepName}
          </>
        }
      >
        {details}
      </Collapsible>
    </li>
  )
}
