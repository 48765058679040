import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import store from '__state/store.js'
import App from '__src/root/App.js'
import { registerFontAwesomeIcons } from '__src/root/Icons.js'

import '@nike/epic-react-ui/dist/styles/main.css'
// Import CSS generated by our 'build:stylus' script
import '__src/index.css'

// Start mock service worker for local testing & development
if (process.env.NODE_ENV === 'dev') {
  const { worker } = require('__src/mocks/browser.js')
  worker.start({
    // Avoid console warnings about unhandled requests
    onUnhandledRequest: 'bypass',
  })
}

// All fontawesome icons used by the app must be registered here
registerFontAwesomeIcons()

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
