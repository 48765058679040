import React from 'react'
import { useRoutes } from 'raviger'
import { LoginRequired, useAuth, useAuthStore, selectUsername } from '@nike/aegis-auth-react'
import client from '__src/auth/client.js'
import '@nike/epic-react-ui/dist/styles/main.css'
import '../stylus/app.styl'
import { Button, Spinner } from '@nike/epic-react-ui'
import { Alerts, NotFound, OfflineBanner } from '__components'
import { DetailPageState } from './DetailPageState'
import config from '__config'
import routes from '__src/routes.js'
import { GitHubState } from '../github/GitHubAuth'
import InterceptGitHubAuthCode from '../github/InterceptGitHubAuthCode.js'
import { ViewerState } from '../users/ViewerContext'
import { NarkProvider } from '__util/nark'
import Navigation from './Navigation.js'
import ErrorBoundary from '__util/ErrorBoundary'

/**
 * This component must run *before* okta-auth-js is invoked in AppWithOktaAuth,
 * because the `code` param returned by GitHub needs to be removed first. Otherwise,
 * okta-auth-js will start a new login flow (and fail because its token store is
 * cleared due to the github auth redirect).
 */
export function App() {
  return <InterceptGitHubAuthCode fallback={() => <AppWithOktaAuth />} />
}

function AppWithOktaAuth() {
  const { basePath, platformId } = config
  const { loginComplete, accessToken } = useAuth(client)
  const email = useAuthStore(selectUsername)

  const route = useRoutes(routes, { basePath, routeProps: { accessToken, platformId } }) || (
    <NotFound />
  )

  if (!loginComplete) return <Spinner large className='centered' />
  return (
    <ViewerState email={email}>
      <Navigation accessToken={accessToken}>
        <Alerts>
          <DetailPageState>
            <OfflineBanner />
            <main className='app-container'>
              <LoginRequired client={client} Button={Button}>
                <NarkProvider accessToken={accessToken}>
                  <ErrorBoundary>
                    <GitHubState>{route}</GitHubState>
                  </ErrorBoundary>
                </NarkProvider>
              </LoginRequired>
            </main>
          </DetailPageState>
        </Alerts>
      </Navigation>
    </ViewerState>
  )
}

export default App
