import React, { useContext, useReducer } from 'react'
import { api } from '__util'
import { AlertsContext } from '__components'
import { IconButton, Modal, Spinner } from '@nike/epic-react-ui'
import { SearchContext, SearchResults } from '__components/search'
import { FileTrackerButtonBar } from './FileTrackerButtonBar'
import showDetailsDialog from './DetailsDialog'
import showResubmitDialog from './ResubmitDialog'
import { TrackerTable } from './TrackerTable'

const initialState = {
  waiting: '',
  dialog: { open: false, content: '', redoSearchOnClose: false },
}
const maxFilesToResubmit = 25

export const FileTrackerSearchResults = (props) => {
  const search = useContext(SearchContext)
  const alerts = useContext(AlertsContext)
  const trackerReducer = (state, action) => {
    switch (action.type) {
      case 'waiting':
        return { ...state, waiting: action.kind }
      case 'doneWaiting':
        return { ...state, waiting: '' }
      case 'openDialog':
        return {
          ...state,
          dialog: {
            open: true,
            content: action.content,
            redoSearchOnClose: action.redoSearchOnClose,
          },
        }
      case 'closeDialog': // This resets dialog and also picked state
        if (action.redoSearchOnClose) {
          return { ...initialState }
        } else {
          return { ...initialState, picked: state.picked }
        }
      default:
        console.error('unexpected action type: ', action.types)
    }
    return state
  }

  const [tracker, dispatch] = useReducer(trackerReducer, initialState)

  const onResubmit = (env, selectedRows) => {
    dispatch({ type: 'waiting', kind: 'resubmit' })
    console.log('---> selectedRows', selectedRows)
    const selectedCount = selectedRows.length
    const nonZeroTransferIds = selectedRows
      .filter((row) => row.filesize > 0)
      .map((row) => row.transferid)
    console.log('---> nonZeroTransferIds', nonZeroTransferIds)
    const numSkippedEmptyFiles = selectedCount - nonZeroTransferIds.length
    const transferIds = nonZeroTransferIds.slice(0, maxFilesToResubmit)
    console.log('---> transferIds', transferIds)
    const numSkippedExcessiveFiles = nonZeroTransferIds.length - transferIds.length

    return api
      .resubmitFiles(env, transferIds)
      .then((response) => {
        console.log('---> response', response)
        showResubmitDialog({
          dispatch,
          resubmitResults: response.results,
          searchResults: search.items,
          numSkippedEmptyFiles,
          numSkippedExcessiveFiles,
          maxFilesToResubmit,
        })
      })
      .catch((response) => {
        console.error(response)
        const content = (
          <div className='epic-color-error'>
            Something went wrong. Please try again or contact an MFT admin.
            <hr />
            <pre>{JSON.stringify(response)}</pre>
          </div>
        )

        dispatch({ type: 'openDialog', content })
      })
      .finally((response) => dispatch({ type: 'doneWaiting' }))
  }

  const onViewDetails = (env, selectedRow) => {
    dispatch({ type: 'waiting', kind: 'getDetails' })

    // This button is enabled only when a single row is picked, so pickedRows should have length 1
    const transferId = selectedRow.transferid
    return api
      .getFileDetails(env, transferId)
      .then((response) => {
        showDetailsDialog(env, transferId, response, dispatch)
      })
      .catch((response) => {
        console.error(response.errors)
        alerts.add({
          message: 'Something went wrong. Please try again or contact an MFT admin.',
          level: 'error',
          dismiss: 500,
        })
      })
      .finally((response) => dispatch({ type: 'doneWaiting' }))
  }

  return (
    <React.Fragment>
      <IconButton onClick={() => search.getItems()} style={{ marginRight: '4px' }} type='search'>
        {search.isLoading ? 'Searching...' : 'Search'}
      </IconButton>
      <SearchResults
        renderResults={(items) => {
          return search.isLoading ? (
            <Spinner />
          ) : (
            <div style={{ margin: '2em' }}>
              <TrackerTable
                rows={items}
                toolbar={(selectedRows) => (
                  <FileTrackerButtonBar
                    selectedRows={selectedRows}
                    trackerWaiting={tracker.waiting}
                    onResubmit={onResubmit}
                    onViewDetails={onViewDetails}
                  />
                )}
              />
            </div>
          )
        }}
      />
      <Modal
        onClose={() => {
          dispatch({ type: 'closeDialog', redoSearchOnClose: tracker.dialog.redoSearchOnClose })
          if (tracker.dialog.redoSearchOnClose) {
            search.getItems()
          }
        }}
        modalSize='lg'
        show={tracker.dialog.open}
      >
        <Modal.Content>{tracker.dialog.content}</Modal.Content>
      </Modal>
    </React.Fragment>
  )
}
