import 'whatwg-fetch'

import { JSONReplacer } from '__components/PrettyJSON'

// Global variable to hold user's okta token. More secure here than local/session storage.
let authToken

export function setAuthToken(token) {
  authToken = token
}

function getAuthHeader() {
  if (authToken) {
    return { Authorization: `Bearer ${authToken}` }
  }
  return {}
}

export const processError = async (r, contentType) => {
  let data
  if (contentType === 'application/json') {
    data = await r.json()
  } else {
    data = await r.text()
  }
  const response = `Request failed with status code ${r.status || r.statusCode}`
  // in some cases returns { message: {errors: "{\"message\": \"...\"}"} }
  const error = new Error(data.message || response)
  // The old axios behavior was to return a response
  // response.message was the parsed message for JSON
  // but running toString produced the "message" above
  error.toString = () => response
  error.data = data
  error.status = r.status
  const { message } = JSON.parse(data.message.errors)
  error.message = message
  console.error(response, error)
  throw error
}

export const processResponse = async (r) => {
  const contentType = r.headers.get('content-type')
  if (!r.ok) {
    return processError(r, contentType)
  }
  if (contentType === 'application/json') {
    return await r.json()
  } else if (contentType === 'application/octet-stream') {
    return await r.blob()
  }
  return await r.text()
}

const mod = {
  async post(url, payload = {}, headers = {}) {
    return await fetch(url, {
      // Stringify objects but not FormData instances
      body: payload.entries ? payload : JSON.stringify(payload, JSONReplacer, 2),
      headers: {
        'Content-Type': payload.entries ? 'multipart/form-data' : 'application/json',
        ...getAuthHeader(),
        ...headers,
      },
      method: 'POST',
      mode: 'cors',
    }).then(processResponse)
  },
  async get(url, queryData, headers = {}) {
    if (queryData) {
      url += '?' + new URLSearchParams(queryData)
    }

    return await fetch(url, {
      headers: { ...getAuthHeader(), ...headers },
      mode: 'cors',
    }).then(processResponse)
  },
  async delete(url, headers = {}) {
    return await fetch(url, {
      headers: { ...getAuthHeader(), ...headers },
      method: 'DELETE',
      mode: 'cors',
    }).then(processResponse)
  },
  async put(url, payload = {}, headers = {}) {
    const contentType = payload.entries ? 'multipart/form-data' : 'application/json'
    return await fetch(url, {
      // Stringify objects but not FormData instances
      body: payload.entries ? payload : JSON.stringify(payload, JSONReplacer, 2),
      headers: { 'Content-Type': contentType, ...getAuthHeader(), ...headers },
      method: 'PUT',
      mode: 'cors',
    }).then(processResponse)
  },
}

export default mod
