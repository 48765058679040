import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { listToSelectOptions } from '__util/select.js'
import { SiteWizardContext } from '../SiteWizard.js'
import { useRealOrMockContext } from '__util/test-helpers'

const options = ['http', 'https']
const defaultValue = 'https'

export default function AckProtocol({ name, stepName }) {
  const { registerField, setField, state } = useRealOrMockContext(SiteWizardContext)

  React.useEffect(
    () => registerField(stepName, name, [], defaultValue),
    [name, registerField, stepName]
  )

  return (
    state.fields.ackType === 'async' && (
      <Field>
        <Label label='Async Acknowledgement Protocol'>
          <HelpText></HelpText>
          <RadioGroup
            list={listToSelectOptions(options)}
            onChange={(value) => setField(name, value)}
            value={state.fields[name] || ''}
          />
        </Label>
      </Field>
    )
  )
}
