import { Select } from '@nike/epic-react-ui'
import React from 'react'
import { Field, HelpText } from '__components'
import { findValue, listToSelectOptions } from '__util/select'

export default function UploadedCertField({ uploadedCertNames, onChange, selectedCertName }) {
  const options = listToSelectOptions(uploadedCertNames)
  options.push({
    value: 'other',
    label: 'Other - I will upload one now',
  })

  return (
    <Field>
      <HelpText>Either choose an existing cert name or provide a new one.</HelpText>
      <Select
        onChange={onChange}
        options={options}
        value={findValue(options, selectedCertName || 'other')}
      />
    </Field>
  )
}
